import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Acoes, BtnAcao } from './apontamentos.styles';

import Title from '../../../../components/Title/Title';
import Filter from '../../../../components/Filter/Filter';
import Table from '../../../../components/Table/Table';
import FilterDeployment from '../../../../components/FilterDeployment/FilterDeployment';

import Avaliar from './modals/avaliar';
import Ver from './modals/ver';
import Editar from './modals/editar';
import Remover from './modals/remover';
import GerarLink from './modals/gerarlink';
import Reatribuir from './modals/reatribuir';

import { modalActions, deploymentsActions } from '../../../../redux/actions';
import { getWorkOrders, getStatusAvailable } from '../../services/workorder';
import { getDeployments, pathCacheResolver } from '../../services/deployments';

const filters = [
    {value:'apontamento', label:'Apontamentos Em Andamento'},
    {value:'realizado', label:'Pendências de Aprovação'},
    {value:'aprovado', label:'Histórico'}
];

function Apontamentos () {

    const [workOrderList, setWorkOrderList] = useState([]);
    const [filter, setFilter] = useState('apontamento')
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});
    const [statusAvailable, setStatusAvailable] = useState([]);
    const [filterDeployment, setFilterDeployment] = useState(null);

    const clientId = useSelector(state=> state.permissionsState.clientId);
    const dispatch = useDispatch();

    const statusRealizadoId = useRef(null);

    const getListing = useCallback(async () => {
        try {
            let wo = await getWorkOrders({
                status:filter,
                type:'workorder',
                order_field:orderBy.orderBy,
                order:orderBy.orderDir,
                deployment_id: filterDeployment
            });

            if (wo && wo.payload && wo.payload.objects){
                let woList = wo.payload.objects.map(i=>i.workorder);
                setWorkOrderList(woList);
            }
        } catch (e) {
            console.error(e);
        }
    }, [filter, filterDeployment, orderBy]);

    const handleFilter = (item) => {
        setFilter(item ? item.value : null);
    };

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    };

    const handleFilterDeployment = (deployment_id) => {
        setFilterDeployment(deployment_id);
    };

    const handleAvaliar = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Avaliar statusAvailable={statusAvailable} id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, statusAvailable]);

    const handleVer = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Ver id={id}/>));
    }, [dispatch]);

    const handleEditar = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Editar id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const handleRemover = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('compact'));
        dispatch(modalActions.setContent(<Remover id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const handleGerarLink = useCallback((id, deployment) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('compact'));
        dispatch(modalActions.setContent(<GerarLink clientId={clientId} id={id} deployment={deployment} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, clientId]);

    const handleReatribuir = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('compact'));
        dispatch(modalActions.setContent(<Reatribuir clientId={clientId} id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, clientId]);

    const renderOptionsForStatus = useCallback((val) => {
        if (!Boolean(statusAvailable && statusAvailable[val])) {
            return null;
        }

        return statusAvailable[val].name || '';
    }, [statusAvailable]);

    const columns = useMemo(() => ([
        {key:'thread_id', label:'#', width: 60},
        {key:'status', label:'Status', adapter: (val, row) => (renderOptionsForStatus(val)), width: 200},
        {key:'deployment', label:'Operação', adapter: (val, row) => pathCacheResolver(row)},
        {key:'createdBy', label:'Criado por'},
        {key:'createdAt', label:'Horário'},
        {key:'actions', label:'Ações', width: 240, adapter: (val, row) => (<Acoes>
            {statusRealizadoId.current && statusRealizadoId.current === parseInt(row.status) ? <BtnAcao onClick={() => handleAvaliar(row.id)} icon={"\\F05DA"} title="Avaliar"/> : null}
            <BtnAcao onClick={() => handleVer(row.id)} icon={"\\F0349"} title="Ver"/>
            <BtnAcao onClick={() => handleEditar(row.id)} icon={"\\F03EB"} title="Editar"/>
            <BtnAcao onClick={() => handleRemover(row.id)} icon={"\\F01B4"} title="Excluir"/>
            <BtnAcao onClick={() => handleGerarLink(row.id, row.deployment)} icon={"\\F1322"} title="Gerar Link de Serviço"/>
            <BtnAcao onClick={() => handleReatribuir(row.id)} icon={"\\F000E"} title="Reatribuir"/>
        </Acoes>)}
    ]),[statusRealizadoId, handleEditar, handleAvaliar, handleVer, handleRemover, renderOptionsForStatus, handleGerarLink, handleReatribuir]);

    useEffect(() => {
        if (clientId) {
            getListing(clientId);
        }
    }, [getListing, clientId]);

    useEffect(() => {
        (async () => {
            try {
                let res = await getDeployments();
                let deployments = res.payload.objects.map(i=>i.deployment)
                if (deployments && deployments.length) {
                    dispatch(deploymentsActions.setDeployments(deployments));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            let status = await getStatusAvailable();

            let statusById = {};
            for (let s of status) {
                statusById[s.id] = s;
                if (String(s.name).toLowerCase() === 'realizado') {
                    statusRealizadoId.current = parseInt(s.id);
                }
            }

            setStatusAvailable(statusById);

            /*let result = {};
            for (let s of status) {
                result[s.id] = s.allowed_next_status.map(id => statusById[id]);
            }
            setStatusAvailable(result);

            */

        })();
    }, []);


    return <Container>
            <Title>Apontamentos</Title>
            <FilterDeployment value={filterDeployment} onChange={(v)=>{handleFilterDeployment(v)}} />
            <Filter
                defaultValue={{value:'apontamento', label:'Apontamentos Em Aberto'}}
                items={filters}
                onChange={handleFilter}
            />
            <Table
                onOrderBy={handleOrderBy}
                columns={columns}
                data={workOrderList}
            />
    </Container>
}

export default Apontamentos;
