import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getAssetData, updateAssetData } from '../../../services/assets';
import { getDeployments } from '../../../services/deployments';
import { getProperty } from '../properties';

import { Row, Label, Select, ButronBar, Button, ModelContent, Error } from '../ativos.styles';
import Title from '../../../../../components/Title/Title';
import FormBuilderOE from '../../../../../components/FormBuilderOE/FormBuilderOE';
import DatePicker from "react-datepicker";

const reduceItemToData = (item) => {
    return item.property ? item.property.reduce((acc, cur) => ({...acc, [cur.key]: cur.value}), {}) : {}
}
function Editar(props) {
    const [item, setitem] = useState('');
    const [error, setError] = useState('');
    const [deployments, setDeployments] = useState([]);
    const [formImovel, setFormImovel] = useState('');
    const [formTipo, setFormTipo] = useState('');
    const [formData, setFormData] = useState([]);
    const [formProximaLimpeza, setFormProximaLimpeza] = useState('');
    
    const modalState = useSelector(state => state.modalState.state)
    const assetConfig = useSelector(state => state.configState.app_asset_config);

    const dispatch = useDispatch();

    const cleanForm = () => {
        setFormImovel('');
        setFormTipo('');
        setFormData([]);
        setFormProximaLimpeza('');
    };

    const formFrequenciaLimpeza = useMemo(()=>{
        return assetConfig && formTipo && assetConfig[formTipo] ? (assetConfig[formTipo]['frequencia_limpeza'] ?? '') : '';
    },[formTipo, assetConfig]);

    const propsWithType = useMemo(()=> {
        let propVal = Object.keys(formData).filter(key=>key!=='tipo').map(key => ({key, value: formData[key]}));
        let result = [...propVal, {key: 'tipo', value: formTipo}]
        return result;
    }, [formTipo, formData]);

    const currentForm = useMemo(()=>{
        let result = props.form[formTipo] || [];
        let data = reduceItemToData(item);
        result = result.map(i => data[i.name] ? ({...i, default: data[i.name]}) : i);
        return result;
    },[props.form, formTipo, item]);

    useEffect(() => {
        if (item) {
            setError('');
            setFormImovel(item.deployment_id || '');
            setFormTipo(getProperty('tipo', item.property) || '');
            setFormData(reduceItemToData(item));
            dispatch(modalActions.setState('opened'));
        }
    }, [item, dispatch]);


    useEffect(()=>{
        (async () => {
            if (modalState === 'loading') {
                let editData = await getAssetData(props.id);
                if (editData.payload.objects) {
                    setitem((editData.payload.objects[0] || {}).asset || null);
                }

                let deployment = await getDeployments();
                if (deployment && deployment.payload && deployment.payload.objects) {
                    setDeployments(deployment.payload.objects.map(i=>i.deployment) || []);
                }
            }
        })();
    }, [dispatch, props.id, modalState]);

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        let success = await updateAssetData(props.id, {
            ...item,
            deployment_id: formImovel,
            property: propsWithType,
            proxima_limpeza: formProximaLimpeza,
            frequencia_limpeza: formFrequenciaLimpeza
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
            } else {
                cleanForm();
                setError('');
                dispatch(modalActions.setState('close'));
                if (typeof props.onRefresh === 'function') {
                    props.onRefresh();
                }
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, item, formImovel, propsWithType, formProximaLimpeza, formFrequenciaLimpeza]);

    return <>
        <Title className={'modal'}>Editar Ativo</Title>
        <ModelContent>
        {(item) ? <>
            <Row>
                <Label>Operação</Label>
                <Select onChange={(e)=>setFormImovel(e.target.value)} value={formImovel}>
                    <option value=""></option>
                    {deployments && deployments.length ? deployments.map(deployment => (
                        <option key={deployment.id} value={deployment.id}>{deployment.nickname || deployment.alias || ''}</option>
                    )) : null }
                </Select>
            </Row>
            <Row>
                <Label>Tipo</Label>
                <Select onChange={(e)=>setFormTipo(e.target.value)} value={formTipo}>
                    <option value=""></option>
                    {props.types ? Object.keys(props.types).map(tipo => (
                        <option key={tipo} value={tipo}>{props.types[tipo] || ''}</option>
                    )) : null}
                </Select>
            </Row>
            <Row>
                <Label>Proxima atividade de limpeza</Label>
                <Label>
                    <DatePicker className="input-calendar" onChange={(e)=>setFormProximaLimpeza(e)} selected={formProximaLimpeza} dateFormat={'dd/MM/yyyy'}/>
                </Label>
            </Row>
            <FormBuilderOE form={currentForm} onChange={(value)=>{setFormData(value)}}/>
        </> : null}
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Editar;