import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    height: 135px;
    min-height: 135px;
    max-height: 135px;
    overflow: auto;
    border: 1px solid #999;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

export const Item = styled.div`
    flex: 1;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    padding: 0 5px;
    color: #000;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    &.active {
        background-color: #444;
        color: #fff;
    }
`;