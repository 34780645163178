import { DeploymentsTypes } from '../constants/deploymentConstants';

const setDeployments = (deployments) => ({
    type: DeploymentsTypes.SET_DEPLOYMENTS,
    deployments,
});
const setCurrentDeployment = (deployment_id) => ({
    type: DeploymentsTypes.SET_CURRENT_DEPLOYMENT,
    deployment_id,
});

const deploymentsActions = {
    setDeployments,
    setCurrentDeployment
};

export default deploymentsActions;