import { modalActions } from '../redux/actions';
import { Store } from '../redux/stores';

export const startLoading = () => {
    let id = String(Math.random() * 899999 + 100000) + (new Date()).getTime();
    Store.dispatch(modalActions.setLoading(id));
    return id;
}

export const finishLoading = (id) => {
    Store.dispatch(modalActions.setLoaded(id));
}

window.store = Store;