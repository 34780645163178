import styled from "styled-components"; 

const processWidth = (props) => {
    let res = {};

    if (props.flex) {
        res['flex'] = props.flex;
    } else if (props.width) {
        res['width'] = String(props.width) + 'px';
    } else {
        res['flex'] = 1;
    }

    return res;
}

export const Container = styled.div`
    padding: 20px 0;
    width: 100%;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    @media only screen and (max-width: 900px) {
        & {
            min-width: 800px;
            padding-right: 20px;
        }
    }
`;

export const ColumnHeader = styled.div.attrs(props=>({
    style: processWidth(props)
}))`
    background-color: #333;
    color: #fff;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 0 5px;
    height: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 0;
        height: 0;
        border: 5px solid transparent;
    }

    &.asc:before{
        border-top: 5px solid #fff;
        transform: translate3d(0, -2px, 0);
    }
    &.desc:before{
        transform: translate3d(0, -8px, 0);
        border-bottom: 5px solid #fff;
    }

`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;

    &:nth-child(even) {
        background-color: #eee;
    }

    &:hover {
        background-color: #e0e0e0;
    }

    @media only screen and (max-width: 900px) {
        & {
            min-width: 800px;
            padding-right: 20px;
        }
    }
`;

export const Column = styled.div.attrs(props=>({
    style: processWidth(props)
}))`
    color: #333;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0 5px;
    min-height: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
`;