import { useState } from 'react';
import { Container, Label, Items, Item } from './Filter.styles';

function Filter (props) {
    const [selected, setSelected] = useState(props.defaultValue || {});

    const handleSelect = (item) => {
        if (props.enableNone) {
            setSelected(state => state !== item ? item : null);
        } else {
            setSelected(item);
        }
        
        if (typeof props.onChange === 'function') {
            props.onChange(selected !== item ? item : null);
        }
    }

    return <Container>
        {!props.hideLabel ? <Label>Filtrar por:</Label> : null}
        <Items>
        {(props.items && props.items.length) ? props.items.map(item => (
            <Item key={item.value} className={selected && selected.value === item.value ? 'active' : ''} onClick={()=>handleSelect(item)}>{item.label}</Item>
        )) : null}
        </Items>
    </Container>
}

export default Filter;