import styled from "styled-components"; 

export const Container = styled.div`
    padding: 20px;
`;

export const Checkbox = styled.input.attrs(()=>({
    type: 'checkbox'
}))`
`;

export const Acoes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 1;

    .tablerow:hover & {
        opacity: 1;
    }

    @media only screen and (max-width: 900px) {
        & {
            opacity: 1;
        }
    }
`;

export const BtnAcao = styled.div`
    width: 30px;
    height: 30px;
    position: relative;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: #333;
    }

    &:hover:before {
        color: #fff;
    }


    &:before {
        font-size: 20px;
        content: '${props => props.icon}';
        font-family: "Material Design Icons";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #333;
    }
`;