import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { deleteUser } from '../../../services/users';

import { ButronBar, Button, ModelContent, Error } from '../users.styles';
import Title from '../../../../../components/Title/Title';

function Remover(props) {
    const [error, setError] = useState('');
    
    const modalState = useSelector(state => state.modalState.state)

    const dispatch = useDispatch();

    useEffect(()=>{
        if (modalState === 'loading') {
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props.id, modalState]);

    const handleRemover = useCallback(async () => {
        dispatch(modalActions.setState('loading'));
        let success = await deleteUser(props.id);

        if (success) {
            setError('');
            dispatch(modalActions.setState('close'));
            if (typeof props.onRefresh === 'function') {
                props.onRefresh();
            }
        } else {
            setError('Ocorreu um erro ao remover. O registro pode já ter sido removido ou você não possui permissão para removê-lo');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props]);

    return <>
        <Title className={'modal'}>Remover Usuário</Title>
        <ModelContent>
            Deseja realmente remover o usuário selecionado?
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleRemover()} color="#f00">Remover</Button>
        </ButronBar>
    </>
}

export default Remover;