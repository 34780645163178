import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getUserData } from '../../../services/users';

import { Row, Label, Value, ModelContent, ButronBar, Button } from '../users.styles';
import Title from '../../../../../components/Title/Title';
import { funcoes } from '../users';

function Ver(props) {
    const [dataCPF, setDataCPF] = useState('');
    const [dataNome, setDataNome] = useState('');
    const [dataEmail, setDataEmail] = useState('');
    const [dataCelular, setDataCelular] = useState('');
    const [dataEmpresaResponsavel, setDataEmpresaResponsavel] = useState('');
    const [dataEmpresaUsuario, setDataEmpresaUsuario] = useState([]);
    const [dataFuncao, setDataFuncao] = useState('');
    
    const modalState = useSelector(state => state.modalState.state)

    const clients = useSelector(state=> state.permissionsState.clients);
    
    const listaEmpresas = useMemo(() => {
        return clients.map(client => ({
            value: client.clients_id,
            label: client.clients_name
        }));
    },[clients]);

    const empresaResponsavel = useMemo(()=>{
        let item = listaEmpresas.filter(i => String(i.value) === String(dataEmpresaResponsavel))[0];
        return item ? item.label : '';
    },[listaEmpresas, dataEmpresaResponsavel]);

    const empresaUtilizador = useMemo(()=>{
        let items = listaEmpresas.filter(i => dataEmpresaUsuario.some(j=> String(j) === String(i.value)));
        return items ? items.map(item=>item.label).join(', ') : '';
    },[listaEmpresas, dataEmpresaUsuario]);

    const dispatch = useDispatch();

    useEffect(()=>{
        (async () => {
            let viewData = await getUserData(props.id);
            let item = [];
            if (viewData.payload.objects) {
                item = viewData.payload.objects.map(i=>i.user)[0];
            }

            if (modalState === 'loading') {
                if (item) {
                    setDataCPF(item.cpf);
                    setDataNome(item.nome);
                    setDataEmail(item.email);
                    setDataCelular(item.celular);
                    setDataEmpresaResponsavel(item.empresaResponsavel);
                    setDataEmpresaUsuario(item.empresaUsuario);
                    setDataFuncao(item.funcao);
                }
                dispatch(modalActions.setState('opened'));
            }
        })();
    }, [dispatch, props.id, modalState]);

    return <>
        <Title className={'modal'}>Visualizar Dados</Title>
        <ModelContent>
            <Row>
                <Label>CPF</Label>
                <Value>{dataCPF}</Value>
            </Row>
            <Row>
                <Label>Nome Completo</Label>
                <Value>{dataNome}</Value>
            </Row>
            <Row>
                <Label>Email</Label>
                <Value>{dataEmail}</Value>
            </Row>
            <Row>
                <Label>Celular</Label>
                <Value>{dataCelular}</Value>
            </Row>
            <Row>
                <Label>Empresa Responsável</Label>
                <Value>{empresaResponsavel}</Value>
            </Row>
            <Row>
                <Label>Empresas que o usuário conseguirá visualizar</Label>
                <Value>{empresaUtilizador}</Value>
            </Row>
            <Row>
                <Label>Função</Label>
                <Value>{funcoes[dataFuncao] || ''}</Value>
            </Row>
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
        </ButronBar>
    </>
}

export default Ver;