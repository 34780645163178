import { useEffect, useReducer } from 'react';
import { Container, Row, Label, Value, Input, Select, Hidden } from './FormBuilderOE.styles';

const valuesReducer = (state, action) => {
        switch(action.type) {
            case 'set':
                let newState = {...state};
                newState[action.payload.key] = action.payload.value
                return newState;
            case 'reset':
                return {...action.payload};
            default:
                return {};
        }
}

const getInitialState = (form) => {
    let initial = {};

    for (let f of form) {
        if (f.default) {
            initial[f.name] = f.default;
        }
    }

    return initial;
}

function FormBuilderOE (props) {

    const [values, dispatchValues] = useReducer(valuesReducer, getInitialState(props.form));

    useEffect(() => {
        dispatchValues({type:'reset', payload: getInitialState(props.form)});
    }, [props.form])

    useEffect(() => {
        if (props.data) {
            dispatchValues({type:'reset', payload: props.data});
        }
    }, [props.data])

    useEffect(() => {
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(values);
        }
    }, [values, props])

    const renderForm = () => {
        if (!props.form) {
            return null;
        }

        let result = [];
        for (let f of props.form) {
            result.push(<Row key={f.name}>
                {f.type !== 'hidden' ? <Label>{f.label}</Label> : null }

                {f.type === 'input' ? <Input value={values[f.name] || ''} onChange={(e)=>dispatchValues({type:'set', payload: {key:f.name, value:e.target.value}})} /> : null}
                {f.type === 'hidden' ? <Hidden value={values[f.name] || ''} /> : null}
                {f.type === 'select' ? <Select value={values[f.name] || ''} onChange={(e)=>dispatchValues({type:'set', payload: {key:f.name, value:e.target.value}})}>
                    <option value=""></option>
                    {f.options && f.options.length ? f.options.map(o => (
                        <option key={o.key} value={o}>{o}</option>
                    )) : null}
                </Select> : null}
            </Row>);
        }

        return result;
    }

    const renderView = () => {
        if (!props.form) {
            return null;
        }

        let result = [];
        for (let f of props.form) {
            result.push(<Row key={f.name}>
                {f.type !== 'hidden' ? <Label>{f.label}</Label> : null }

                {f.type === 'input' || f.type === 'select' ? <Value>{values[f.name] || ''}</Value> : null}
            </Row>);
        }

        return result;
    }

    return <Container {...props}>
        {props.viewMode ? renderView() : renderForm()}
    </Container>
}

export default FormBuilderOE;