
import { ConfigTypes } from '../constants/configConstants';

const initialState = {
    app_id: 0,
    app_pipeline: null,
    app_asset_types: {},
    app_asset_forms: {},
    app_asset_config: {},
};

export const configReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConfigTypes.SET_APP_ID:
            return {
                ...state,
                app_id: action.id
            };

        case ConfigTypes.SET_APP_PIPELINE:
            return {
                ...state,
                app_pipeline: action.pipeline
            };

        case ConfigTypes.SET_APP_ASSET_TYPES:
            return {
                ...state,
                app_asset_types: action.types
            };

        case ConfigTypes.SET_APP_ASSET_FORMS:
            return {
                ...state,
                app_asset_forms: action.forms
            };

        case ConfigTypes.SET_APP_ASSET_CONFIG:
            return {
                ...state,
                app_asset_config: action.config
            };

        case 'RESET':
            return {...initialState};
        
        default:
            return state;
    }
};