import { useSelector } from 'react-redux';
import { Container } from './LoadingIndicator.styles';

function LoadingIndicator () {
    const loading = useSelector(s => s.modalState.loading);
    
    return <Container className={loading.length > 0 ? 'active' : ''}>
        Aguarde...
    </Container> 
}

export default LoadingIndicator;