import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const pathCacheResolver = (row) => {
    if (!row.path_cache) {
        return '';
    }
    return row.path_cache.split('|').map(path=> {
        let p = path.split(':');
        return p[1] || p[0] || ''
    }).join(' / ');
}

export const getDeployments = async () => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/deployment?count=1000', {params: {
            source: 'app',
            source_id: '7'
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const getDeploymentData = async (id) => {
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/deployment/' + id);
        return resp.data;
    } catch (e) {
        return false;
    }
}

export const deleteDeployment = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/delete/deployment/',{
            data: {
                payload: {
                    deployment: {
                        id
                    }
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const deleteDeployment2 = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/milliclick/deployment/delete',{
            data: {
                payload: {
                    deployment: {
                        id
                    }
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}


export const postDeployments = async (data) => {
    let formData = new FormData();

    formData.append("nickname", data.nickname);
    formData.append("scale", data.scale);
    formData.append("parent_id", data.parent_id);
    formData.append("description", data.description);
    formData.append("layers[camadaMilliclick]", data.camadaMilliclick[0]);
    formData.append("layers[blueprint]", data.blueprint[0]);

    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/new/deployment', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}
