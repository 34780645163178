
import { PermissionTypes } from '../constants/permissionsConstants';

const initialState = {
    permissions: {},
    clients: [],
    clientId: null,
    me: {},
};

export const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PermissionTypes.SET_PERMISSION:
            return {
                ...state,
                permissions: action.permissions
            };
        
        case PermissionTypes.SET_CLIENTS:
            return {
                ...state,
                clients: action.clients
            };
        
        case PermissionTypes.SET_CLIENT_ID:
            return {
                ...state,
                clientId: action.clientId
            };

        case PermissionTypes.SET_ME:
            return {
                ...state,
                me: action.me
            };
        
        case 'RESET':
            return {...initialState};
        
        default:
            return state;
    }
};