import styled from "styled-components"; 

export const Container = styled.div`
    padding: 20px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 10px;
`;

export const Label = styled.div`
    padding: 0 20px 0 0;
    flex: 1;
`;

export const Input = styled.input`
    border: 1px solid #999;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    flex: 1;
`;

export const Select = styled.select`
    border: 1px solid #999;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    flex: 1;
`;

export const TextArea = styled.textarea`
    border: 1px solid #999;
    height: 110px;
    min-height: 110px;
    box-sizing: border-box;
    padding: 5px 10px;
    flex: 1;
    width: 50%;
    min-width: 50%;
    max-width: 50%;
`;

export const RadioItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 10px;
`;

export const Radio = styled.input.attrs(()=>({
    type: 'radio'
}))`
    margin-right: 10px;
`;

export const UploadItem = styled.input.attrs(()=>({
    type: 'file'
}))`
    opacity: 0;
    position: absolute;
    z-index: -1;
`;

export const UploadLabel = styled.label`
    cursor: pointer;
    border: 1px solid #999;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    flex: 1;
    position: relative;
    padding-left: 115px;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    &:before {
        content: 'Escolher arquivo';
        font-size: 12px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: #333;
        color: #fff;
        height: 30px;
        width: 100px;
        text-align: center;
        box-sizing: border-box;
        padding: 0;
        line-height: 30px;
        z-index: 1;
    }
`;

export const RadioText = styled.label`
    color: #000;
    text-wrap: normal;
`;

export const RadioList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1;
`;
