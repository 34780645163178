import { ConfigTypes } from '../constants/configConstants';

const setAppId = (id) => ({
    type: ConfigTypes.SET_APP_ID,
    id,
});

const setAppPipeline = (pipeline) => ({
    type: ConfigTypes.SET_APP_PIPELINE,
    pipeline,
});

const setAppAssetTypes = (types) => ({
    type: ConfigTypes.SET_APP_ASSET_TYPES,
    types,
});

const setAppAssetForms = (forms) => ({
    type: ConfigTypes.SET_APP_ASSET_FORMS,
    forms,
});

const setAppAssetConfig = (config) => ({
    type: ConfigTypes.SET_APP_ASSET_CONFIG,
    config,
});

const configActions = {
    setAppId,
    setAppPipeline,
    setAppAssetTypes,
    setAppAssetForms,
    setAppAssetConfig
};

export default configActions;