import axios from 'axios';
import { Switch, Route } from "react-router-dom";

import Clientbar from '../../components/Clientbar/Clientbar';
import Sidemenu from '../../components/Sidemenu/Sidemenu';
import SidemenuItem, { SidemenuSeparator } from '../../components/SidemenuItem/SidemenuItem';
import Clients from './pages/clients/clients';
import Users from './pages/users/users';
import Imovel from './pages/imovel/imovel';
import Apontamentos from './pages/apontamentos/apontamentos';
import Servicos from './pages/servicos/servicos';
import Ativos from './pages/ativos/ativos';
import Arquivos from './pages/arquivos/arquivos';
import Home from './pages/home/home';
import RelatorioServicos from './pages/relatorioservicos/relatorioservicos';
import Profile from './pages/profile/profile';
import Config from './pages/config/config';
import Modal from '../../components/Modal/Modal';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';

import { Content, GlobalStyle } from './milliclick.styles.js';
import { getPermissions, getClientsList, getMe, setClients } from './services/permission';
import { configActions, permissionsActions } from '../../redux/actions';

import './milliclick.theme.css';
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppConfig } from './services/config';

function Milliclick() {

    const permissionsState = useSelector(state=> state.permissionsState.permissions);
    const clientsList = useSelector(state=> state.permissionsState.clients);
    const dispatch = useDispatch();    

    useEffect(()=>{
        document.title = 'Milliclick';

        let icon = document.querySelector('link[rel="icon"]');
        if (icon) {
            icon.href = '/app/favicon/mclick.ico';
        }
    },[]);

    useEffect(()=>{
        (async ()=>{

            axios.defaults.headers.common['X-CSRF-TOKEN'] = localStorage.getItem('csrf-token') || '';

            let cfg = await getAppConfig();
            if (cfg && cfg.payload && cfg.payload.objects && cfg.payload.objects[0] && cfg.payload.objects[0].config) {
                let config = cfg.payload.objects[0].config;
                if (config && config.private && config.private.appId) {
                    dispatch(configActions.setAppId(config.private.appId));
                }
                if (config && config.public && config.public.assets && config.public.assets.asset_types) {
                    dispatch(configActions.setAppAssetTypes(config.public.assets.asset_types));
                }
                if (config && config.public && config.public.assets && config.public.assets.asset_forms) {
                    dispatch(configActions.setAppAssetForms(config.public.assets.asset_forms));
                }
                if (config && config.public && config.public.assets && config.public.assets.asset_config) {
                    console.log('received conf', config.public.assets.asset_config);
                    dispatch(configActions.setAppAssetConfig(config.public.assets.asset_config));
                }
            }

            let res = await getPermissions({resource_type:'app', resource_id:''});
            let permissions = {};

            if (res && res.payload && res.payload.objects) {
                let values = res.payload.objects.map(i=>i.permission);
                for (let item of values) {
                    if (['app.7.manager','app.7.partner','app.7.facility','app.7.cleaner'].indexOf(item)) {
                        permissions[item.alias.substr(6) || '0'] = Boolean(parseInt(item.grant));
                    }
                }

                dispatch(permissionsActions.setPermissions(permissions))
            }

            let cli = await getClientsList();

            if (cli && cli.list) {
                let selectedId = null;
                for (let item of cli.list) {
                    if (item.clients_name === cli.clientView) {
                        selectedId = item.clients_id;
                    }
                }
                dispatch(permissionsActions.setClients(cli.list));
                dispatch(permissionsActions.setClientId(selectedId))
            }

            let me = await getMe();

            if (me && me.payload && me.payload.objects && me.payload.objects[0]) {
                dispatch(permissionsActions.setMe(me.payload.objects[0].user || {}))
            }
        })();
    }, [dispatch]);

    const onChangeClient = async (id) => {
        await setClients(id);
        dispatch(permissionsActions.setClientId(id));
    }

    return <>
        <GlobalStyle/>
        <Clientbar clients={clientsList} onChange={onChangeClient} />
        <Sidemenu home={'/milliclick/'} logo={'milliclick.png'}>
            <SidemenuItem url={'/milliclick/perfil'}>Meu Perfil</SidemenuItem>
            <SidemenuItem url={'/milliclick/'}>Dashboard</SidemenuItem>

            {permissionsState.manager === true ? <SidemenuItem url={'/milliclick/config'}>Classes de Ativos</SidemenuItem> : null}
            <SidemenuSeparator />
            {permissionsState.manager === true || permissionsState.partner === true ? <SidemenuItem url={'/milliclick/cliente'}>Clientes</SidemenuItem> : null}
            {permissionsState.manager === true || permissionsState.facility === true ? <SidemenuItem url={'/milliclick/utilizador'}>Usuários</SidemenuItem> : null }
            {permissionsState.manager === true || permissionsState.partner === true || permissionsState.facility === true ? <SidemenuItem url={'/milliclick/imovel'}>Operações</SidemenuItem> : null }
            <SidemenuSeparator />
            {permissionsState.facility === true || permissionsState.manager === true ? <SidemenuItem url={'/milliclick/apontamentos'}>Apontamentos</SidemenuItem> : null }
            {permissionsState.manager === true || permissionsState.cleaner === true ? <SidemenuItem url={'/milliclick/servicos'}>Serviços</SidemenuItem> : null}
            {permissionsState.manager === true || permissionsState.facility === true  ? <SidemenuItem url={'/milliclick/ativos'}>Ativos</SidemenuItem> : null}
            <SidemenuSeparator />
            {permissionsState.manager === true || permissionsState.facilitymanager === true || permissionsState.facility || permissionsState.partner ? <SidemenuItem url={'/milliclick/relatorioservicos'}>Gerar Relatório de Serviços</SidemenuItem> : null }
            {permissionsState.manager === true || permissionsState.facilitymanager === true || permissionsState.facility || permissionsState.partner ? <SidemenuItem url={'/milliclick/arquivos'}>Repositório de Arquivos</SidemenuItem> : null }

            <SidemenuSeparator />
            <SidemenuItem absoluteUrl={process.env.REACT_APP_HOST + '/account/signout'}>Sair</SidemenuItem>
        </Sidemenu>
        <Content>
            <Switch>
                <Route path="/milliclick/perfil"><Profile /></Route>
                <Route path="/milliclick/config"><Config /></Route>
                <Route path="/milliclick/cliente"><Clients /></Route>
                <Route path="/milliclick/utilizador"><Users /></Route>
                <Route path="/milliclick/imovel"><Imovel /></Route>
                <Route path="/milliclick/apontamentos"><Apontamentos /></Route>
                <Route path="/milliclick/servicos"><Servicos /></Route>
                <Route path="/milliclick/ativos"><Ativos /></Route>
                <Route path="/milliclick/relatorioservicos"><RelatorioServicos /></Route>
                <Route path="/milliclick/arquivos"><Arquivos /></Route>
                <Route path="/milliclick/"><Home /></Route>
            </Switch>
        </Content>
        <Modal />
        <LoadingIndicator />
    </>
}

export default Milliclick;
