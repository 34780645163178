import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import { Store } from '../../redux/stores';

import Milliclick from '../../apps/milliclick/milliclick';

function App() {
    return <div id='app-container'>
        <Provider store={Store}>
            <BrowserRouter basename={'/app'}>
                <div id='app-content'>
                    <Switch>
                        <Route path="/milliclick"><Milliclick /></Route>
                        <Route path="/">
                            <div>Sem aplicativo definido</div>
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </Provider>
    </div>
}

export default App;
