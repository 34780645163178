import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Tabs, Tab, Submit, Success, ErrorMessage } from './clients.styles';

import Title from '../../../../components/Title/Title';

import FormCNPJ from '../forms/FormCNPJ';
import FormCPF from '../forms/FormCPF';

import { postClient } from '../../services/forms';
import { getClientsList } from '../../services/permission';
import { permissionsActions } from '../../../../redux/actions';

function Clients () {
    const [activeTab, setActiveTab] = useState('cnpj');
    const [isValid, setIsValid] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formData = useRef(null);
    const dispatch = useDispatch();

    const handleActiveTab = (value) => {
        setActiveTab(value);
        setIsValid(false);
        setIsSuccess(false);
        setIsError(false);
        setIsLoading(false);
    };

    const handleForm = (data, isValid) => {
        formData.current = data;
        setIsValid(isValid);
    };

    const handleSubmit = async () => {
        if (!isValid || isLoading) {
            return;
        }

        try {
            setIsLoading(true);
            (async () => {
                let resp = await postClient({...formData.current, type: activeTab })
                if (resp.toast && resp.toast.type !== 'success') {
                    throw new Error(resp.toast.message);
                }

                let cli = await getClientsList();

                if (cli && cli.list) {
                    let selectedId = null;
                    for (let item of cli.list) {
                        if (item.clients_name === cli.clientView) {
                            selectedId = item.clients_id;
                            break;
                        }
                    }
                    dispatch(permissionsActions.setClients(cli.list));
                    dispatch(permissionsActions.setClientId(selectedId))
                }

                setIsLoading(false);
                setIsSuccess(resp.toast && resp.toast.message ? resp.toast.message : '');
                setIsError(false);
            })();
        } catch (e) {
            setIsLoading(false);
            setIsSuccess(false);
            setIsError(e.message || 'Ops, tivemos um problema! Os dados que informou não condizem com aqueles da receita ou não parecem ser do representante em questão. Se os problemas persistirem, nos avise em ajuda@ami-hub.com');
        }
    };

    return <Container>
        <Title>Cadastro de novo cliente</Title>
        <Tabs>
            <Tab className={activeTab==='cnpj' ? 'active' : ''} onClick={()=>{handleActiveTab('cnpj')}}>CNPJ</Tab>
            <Tab className={activeTab==='cpf' ? 'active' : ''} onClick={()=>{handleActiveTab('cpf')}}>CPF</Tab>
        </Tabs>
        {isSuccess && <Success>{isSuccess}</Success>}
        {isError && <ErrorMessage>{isError}</ErrorMessage>}
        {activeTab === 'cnpj' ? <FormCNPJ onChange={handleForm}/> : <FormCPF onChange={handleForm}/>}
        <Submit className={(isValid ? 'active ' : '') + (isLoading ? 'spinner-loading' : '')} onClick={handleSubmit}>Enviar</Submit>

    </Container>
}

export default Clients;
