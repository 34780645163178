import { useDispatch } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { ButronBar, Button, ModelContent } from '../../arquivos/arquivos.styles';
import Title from '../../../../../components/Title/Title';

function Error(props) {
    const dispatch = useDispatch();

    return <>
        <Title className={'modal'}>Erro</Title>
        <ModelContent>
            Erro ao gerar relatório<br />
            {props.error || ''}
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}} color="#000">Ok</Button>
        </ButronBar>
    </>
}

export default Error;