import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Acoes, BtnAcao } from './users.styles';

import Title from '../../../../components/Title/Title';
import Table from '../../../../components/Table/Table';
import Actionbar from '../../../../components/Actionbar/Actionbar';
import ActionButton from '../../../../components/ActionButton/ActionButton';

import Adicionar from './modals/adicionar';
import Ver from './modals/ver';
import Editar from './modals/editar';
import Remover from './modals/remover';

import { modalActions } from '../../../../redux/actions';
import { getUsers } from '../../services/users';

export const funcoes = {
    manager: "Gestor",
    partner: "Parceiro Comercial",
    facility: "Gestor de Facilities",
    cleaner: "Técnico de Limpeza",
    nenhum: "Nenhum",
}

function Usuarios () {
    const [userList, setUserList] = useState([]);
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});

    const clientId = useSelector(state=> state.permissionsState.clientId);
    const dispatch = useDispatch();

    const getListing = useCallback(async () => {
        try {
            let res = await getUsers({order_field:orderBy.orderBy, order:orderBy.orderDir});

            if (res && res.payload && res.payload.objects){
                let usersList = res.payload.objects.map(i=>i.user);
                setUserList(usersList);
            }
        } catch (e) {
            console.error(e);
        }
    }, [orderBy]);

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    }

    const handleVer = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Ver id={id}/>));
    }, [dispatch]);

    const handleEditar = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Editar id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const handleRemover = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('compact'));
        dispatch(modalActions.setContent(<Remover id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const handleAdicionar = useCallback((id) => {
        dispatch(modalActions.setState('opened'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Adicionar onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const columns = useMemo(() => ([
        {key:'id', label:'#', width: 60},
        {key:'nome', label:'Nome'},
        {key:'funcao', label:'Função', adapter: (val, row) => funcoes[val]},
        {key:'celular', label:'Celular'},
        {key:'actions', label:'Ações', width: 120, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleVer(row.id)} icon={"\\F0349"} title="Ver"/>
            <BtnAcao onClick={() => handleEditar(row.id)} icon={"\\F03EB"} title="Editar"/>
            <BtnAcao onClick={() => handleRemover(row.id)} icon={"\\F01B4"} title="Excluir"/>
        </Acoes>)}
    ]),[handleEditar, handleVer, handleRemover]);

    useEffect(() => {
        if (clientId) {
            getListing(clientId);
        }
    }, [getListing, clientId]);

    return <Container>
            <Title>Usuários</Title>
            <Actionbar>
                <ActionButton onClick={handleAdicionar}>Cadastrar Usuário</ActionButton>
            </Actionbar>
            <Table
                onOrderBy={handleOrderBy}
                columns={columns} 
                data={userList}
            />
    </Container>
}

export default Usuarios;
