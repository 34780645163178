import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getWorkOrders = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/workorder', {params: {
            source: 'app',
            source_id: '7',
            pipeline: 'milliclick_485723149678',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getWorkOrderTemplates = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/wotemplate',{params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const getWorkOrderQrcodeTemplate = async (clientId, id) => {
    /*try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/wotemplate/'+id+'/qrcode', {
            responseType: 'arraybuffer',
        });
        return resp.data;
    } catch (e) {
        return false;
    }*/
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/shortlink/new',{
            source_type: "app",
            source: 7,
            guest_action: "link",
            client: clientId,
            guest_destination: `milliclick/workorder/new/${clientId}/${id}/milliclick_485723149678`
        } , {
            responseType: 'arraybuffer',
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const getWorkOrderQrcodeEdit = async (clientId, id/*, alias*/) => {
    /*try {
        let resp = await axios.get(REACT_APP_HOST + '/woedit/milliclick_'+alias+'/qrcode', {
            responseType: 'arraybuffer',
        });
        return resp.data;
    } catch (e) {
        return false;
    }*/

    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/shortlink/new',{
            source_type: "app",
            source: 7,
            guest_action: "link",
            guest_destination: `milliclick/workorder/edit/${clientId}/service/${id}`
        } , {
            responseType: 'arraybuffer',
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const putWorkOrder = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/edit/workorder', {
            payload: {
                objects: [
                    {
                        workorder: {
                            id,
                            status: data.status,
                            pipeline_alias: 'milliclick_485723149678',
                            entry: [
                                {
                                    "type": "textarea",
                                    "required": false,
                                    "label": "Text Area",
                                    "className": "form-control",
                                    "name": "textarea-1627579501837-0",
                                    "access": false,
                                    "value": "Status alterado pelo usuário",
                                    "subtype": "textarea"
                                }
                            ]
                        }
                    }
                ]
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getWorkOrderHistory = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/workorder/'+id+'/history', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getWorkOrderData = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/workorder/'+id, {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getWorkOrderEntries = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/workorder/'+id+'/entries', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const updateWorkOrderData = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/edit/workorder3/'+id, {
            payload: {
                object: {
                    workorder: data
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const updateWorkOrderDataID = async (id, data) => {
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/edit/workorder2/'+id, {
            payload: {
                object: {
                    workorder: data
                }
            }
        });
        return resp.data;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const updateWorkOrderDataID2 = async (id, data) => {
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/edit/workorder3/'+id, {
            payload: {
                object: {
                    workorder: data
                }
            }
        });
        return resp.data;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const updateWorkOrderDataID4 = async (id, data) => {
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/edit/workorder4/'+id, {
            payload: {
                object: {
                    workorder: data
                }
            }
        });
        return resp.data;
    } catch (e) {
        console.error(e);
        return false;
    }
};

export const deleteWorkOrder = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/delete/workorder/',{
            data: {
                payload: {
                    objects: [{
                        workorder: {
                            id
                        }
                    }]
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getStatusAvailable = async () => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/workorder/status', {params:{
            source: 'app',
            source_id: '7',
            pipeline: 'milliclick_485723149678'
        }});
        finishLoading(loadingId);
        if (resp.data && resp.data.payload && resp.data.payload.objects) {
            return resp.data.payload.objects.map(i=>i.wo_status) || [];
        }
        return [];
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postWorkOrderTemplate = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/new/wotemplate', data);
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postWorkOrderPipeline = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/new/wopipelinestatus', data);
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const deleteWorkOrderTemplate = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/delete/wotemplate',{
            data: {
                payload: {
                    objects: [{
                        wotemplate: {
                            id
                        }
                    }]
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getServiceReport = async (ids) => {
    const loadingId = startLoading();
    try {
        let resp = axios.post(REACT_APP_HOST + '/milliclick/data/gerarrelatorio',{
            payload: {
                objects: ids.map(id=>({
                    workorder: {
                        id,
                        status: 'Reportado'
                    }
                }))
            }
        }, {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'relatorio.docx');
            document.body.appendChild(link);
            link.click();
            finishLoading(loadingId);
        }).catch(e=> {
            console.log(e);
            finishLoading(loadingId);
        });
        return resp;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        throw new Error(e);
    }
};
