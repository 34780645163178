import { useEffect, useState } from 'react';
import { Container, Item } from './Multiselect.styles';

function Multiselect(props) {

    let [value, setValue] = useState([]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleSelected = (val) => {
        let newValue = value.indexOf(val) >= 0 ? value.filter(i=>i!==val) : [...value, val]

        setValue(newValue);
        if (typeof props.onChange === 'function') {
            props.onChange(newValue);
        }

    }

    return <Container>
        {Boolean(props.items) ? props.items.map(item => (
            <Item key={item.value} className={value.indexOf(item.value) >= 0 ? 'active' : ''} onClick={()=>handleSelected(item.value)}>{item.label}</Item>
        )) : null}
    </Container>
}

export default Multiselect;