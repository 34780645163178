import styled from 'styled-components';

export const Container = styled.div`
    clear:both;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin: 0 0 10px 0;
    padding-bottom: 10px;
    border-bottom: 0px solid #cacaca;
`;

export const ParentItem = styled.div`
    color: #666;
    margin-right: 8px;
    padding-right: 14px;
    position: relative;
    cursor: pointer;

    &:before {
        content: '/';
        position: absolute;
        right: 0;
    }
`;
export const CurrentItem = styled.div`
    color: #000;
    font-weight: bold;
    cursor: pointer;
`;
