import { useCallback } from 'react';
import {useSelector, useDispatch} from 'react-redux';

import { Container, Background } from './Modal.styles';
import { modalActions } from '../../redux/actions';

function Modal () {

    const modalState = useSelector(state => state.modalState.state)
    const modalSize = useSelector(state => state.modalState.size)
    const modalContent = useSelector(state => state.modalState.content)
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(modalActions.setState('closed'));
    }, [dispatch]);

    return <>
        <Background onClick={()=>handleClose()} className={modalState}/>
        <Container className={modalState + ' ' + modalSize}>
            {modalContent}
        </Container> 
    </>
}

export default Modal;