import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getWorkOrderData } from '../../../services/workorder';

import { Row, Label, Value, ModelContent, ButronBar, Button } from '../apontamentos.styles';
import Title from '../../../../../components/Title/Title';
import FormRender from '../../../../../components/FormRender/FormRender';

function Ver(props) {
    const [item, setItem] = useState(null);
    
    const modalState = useSelector(state => state.modalState.state)

    const dispatch = useDispatch();

    useEffect(()=>{
        (async () => {
            let viewData = await getWorkOrderData(props.id);
            let item = null;
            if (viewData.payload.objects) {
                item = (viewData.payload.objects[0] || {}).workorder || null;
            }

            if (modalState === 'loading') {
                setItem(item);
                dispatch(modalActions.setState('opened'));
            }
        })();
    }, [dispatch, props.id, modalState]);

    return <>
        <Title className={'modal'}>Visualizar Dados</Title>
        <ModelContent>
        {(item) ? <>
            <Row>
                <Label>Título</Label>
                <Value>{item.title}</Value>
            </Row>
            <Row>
                <Label>Data</Label>
                <Value>{item.due_date}</Value>
            </Row>
            <Row>
                <FormRender readOnly={true} formData={item.entry} />
            </Row>
        </> : null}
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
        </ButronBar>
    </>
}

export default Ver;