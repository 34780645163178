import { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Submit, Success, ErrorMsg, Collapsable, CollapseTitle, Deployments, DeploymentDetail, DeploymentName, DeploymentInfo, Label, Value, DeploymentsQrCodes, QRCode, LabelQrCode } from './imovel.styles';

import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import DeploymentNav from '../../../../components/DeploymentNav/DeploymentNav';
import Breadcumb from '../../../../components/Breadcumb/Breadcumb';
import Breadcumb2 from '../../../../components/Breadcumb2/Breadcumb2';
import DeletarDeployment from '../../../../components/DeletarDeployment/DeletarDeployment';
import FormImovel from '../forms/FormImovel';

import { convertToBase64 } from '../../../../util/image';

import { postDeployments } from '../../services/deployments';
import { getWorkOrderQrcodeEdit, getWorkOrderQrcodeTemplate } from '../../services/workorder';
import { getDeployments, getDeploymentData } from '../../services/deployments';
import { deploymentsActions } from '../../../../redux/actions';

function Imovel () {
    const [isValid, setIsValid] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [currentDeployment, setCurrentDeployment] = useState(null);
    const [deployments, setDeployments] = useState([]);
    const [deploymentPath, setDeploymentPath] = useState([]);
    const [deploymentDetail, setDeploymentDetail] = useState({});
    const [qrcode1, setQrcode1] = useState(null);
    const [qrcode2, setQrcode2] = useState(null);

    const clientId = useSelector(state=> state.permissionsState.clientId);
    const permissionsState = useSelector(state=> state.permissionsState.permissions);

    const dispatch = useDispatch();

    const formData = useRef(null);

    const getListing = useCallback(async () => {
        try {
            let res = await getDeployments();
            let deployments = res.payload.objects.map(i=>i.deployment)
            if (deployments && deployments.length) {
                setDeployments(deployments);
                dispatch(deploymentsActions.setDeployments(deployments));
            } else {
                setDeployments([]);
                dispatch(deploymentsActions.setDeployments([]));
            }
        } catch (e) {
            console.error(e);
        }
    },[dispatch]);

    useEffect(() => {
        (async () => {
        try {
            if (!currentDeployment) {
                return;
            }

            let res = await getDeploymentData(currentDeployment);
            let deployment = res.payload.objects.map(i=>i.deployment)

            if (!deployment || !deployment[0]) {
                throw new Error('Erro ao obter deployment');
            }

            /*let resTemplate = await getWorkOrderTemplates({deployment_alias: deployment[0].alias});
            let woTemplate = resTemplate.payload.objects.map(i=>i.wotemplate)

            if (!woTemplate || !woTemplate[0]) {
                throw new Error('Erro ao obter woTemplate');
            }*/

            setDeploymentDetail(deployment && deployment[0] ? deployment[0] : {});
            let resQrcode1 = await getWorkOrderQrcodeTemplate(clientId, currentDeployment);
            setQrcode1(resQrcode1 ? convertToBase64(resQrcode1) : null);

            if(permissionsState.manager === true || permissionsState.partner === true) {
                let resQrcode2 = await getWorkOrderQrcodeEdit(clientId, currentDeployment);
                setQrcode2(resQrcode2 ? convertToBase64(resQrcode2) : null);
            }
        } catch (e) {
            console.error(e);
            setDeploymentDetail({});
            setQrcode1(null);
            setQrcode2(null);
        }})();
    }, [currentDeployment, clientId, permissionsState.manager, permissionsState.partner]);

    useEffect(() => {
        let result = [];

        if (currentDeployment === null) {
            setDeploymentPath([]);
        } else {
            let parentItem = currentDeployment;
            while (parentItem !== null) {
                for (let item of deployments) {
                    if (item.id === parentItem) {
                        result.push({
                            id: item.id,
                            value: item.nickname || item.alias || '',
                            blueprint: item.blueprint,
                            blueprintUrl: item.blueprintUrl,
                            dashboard: item.dashboard
                        });
                        parentItem = item.parent_id;
                        break;
                    }
                }
            }

        }

        setDeploymentPath(result.reverse());
    }, [currentDeployment, deployments]);

    const handleForm = (data, isValid) => {
        formData.current = data;
        setIsValid(isValid);
    };

    /*const handleSubmit = async () => {
        if (!isValid) {
            return;
        }

        try {
            let resDpm = await postDeployments({...formData.current});
            let deploymentId = null;
            let woTemplateId = null;

            if (!resDpm) {
                throw new Error('Erro ao criar operação');
            } else {
                if (resDpm && resDpm.payload && resDpm.payload.objects && resDpm.payload.objects[0] && resDpm.payload.objects[0].deployment) {
                    deploymentId = resDpm.payload.objects[0].deployment.id || null;
                }
            }


            let resTpl = await postWorkOrderTemplate({
                alias: formData.current.alias,
                nickname: `Chamado ${formData.current.nickname || ''}`,
                template: [],
                title: `Apontamento de ${formData.current.nickname}`,
                description: 'Gerado pelo app milliclick'
            });

            if (!resTpl) {
                await deleteDeployment(deploymentId);
                throw new Error('Erro ao criar template');
            } else {
                if (resTpl && resTpl.payload && resTpl.payload.objects && resTpl.payload.objects[0] && resTpl.payload.objects[0].wotemplate) {
                    woTemplateId = resTpl.payload.objects[0].wotemplate.id || null;
                }
            }

            let resPipe = await postWorkOrderPipeline({
                pipeline_alias: formData.current.alias,
                wo_template_id: woTemplateId
            });

            if (!resPipe) {
                await deleteDeployment(deploymentId);
                await deleteWorkOrderTemplate(woTemplateId);
                throw new Error('Erro ao criar Pipeline Status');
            }

            let success = Boolean(resDpm) && Boolean(resTpl) && Boolean(resPipe);

            setIsError(!success);
            setIsSuccess(success);
            getListing();
        } catch(e) {
            console.log(e);
            setIsError(true);
            setIsSuccess(false);
        }

    };*/

    const handleSubmit = async () => {
        if (!isValid || isLoading) {
            return;
        }

        setIsLoading(true);
        (async () => {
            try {
                let resDpm = await postDeployments({...formData.current});

                if (!resDpm) {
                    throw new Error('Erro ao criar operação');
                }

                if (resDpm.toast && resDpm.toast.type !== 'success') {
                    throw new Error(resDpm.toast.message);
                }

                console.log('resDpm.toast.type', resDpm.toast.type);

                setIsLoading(false);
                setIsSuccess(resDpm.toast && resDpm.toast.message ? resDpm.toast.message : '');
                setIsError(false);
                getListing();
            } catch(e) {
                setIsLoading(false);
                setIsSuccess(false);
                setIsError(e.message || 'Ops, tivemos um problema! Os dados que informou não condizem com aqueles da receita ou não parecem ser do representante em questão. Se os problemas persistirem, nos avise em ajuda@ami-hub.com');
            }
        })();
    };

    const handleSelectDeployment = (item) => {
        setCurrentDeployment(item.id);
    }

    useEffect(() => {
        if (clientId) {
            getListing();
        }
    }, [getListing, clientId]);

    // const DeletarDeployment = ((id) => {
    //     console.log("link para deletar deployment", id);
    //     (async () => {
    //         try {
    //             // let deletarDeployment = await deleteDeployment(id);
    //         } catch(e) {
    //             setIsLoading(false);
    //             setIsSuccess(false);
    //             setIsError(e.message || 'Ops, tivemos um problema! Se os problemas persistirem, nos avise em ajuda@ami-hub.com');
    //         }
    //     })();
    // });

    return <Container>
        <Title>Operações</Title>
        {permissionsState.manager === true ? <Collapsable className={isCollapsed ? '' : 'active'}>
            <CollapseTitle onClick={()=>setIsCollapsed(state=>!state)}>
                <div>Cadastrar nova operação</div>
            </CollapseTitle>
            
            {isSuccess && <Success onClick={()=>{setIsSuccess(false)}}>{isSuccess}</Success>}
            {isError && <ErrorMsg onClick={()=>{setIsError(false)}}>{isError}</ErrorMsg>}
            
            <FormImovel onChange={handleForm} imoveis={deployments}/>

            <Submit className={(isValid ? 'active ' : '') + (isLoading ? 'spinner-loading' : '')} onClick={handleSubmit}>Enviar</Submit>
        </Collapsable> : null}

        <Subtitle>Operações cadastradas</Subtitle>

        <p id="status_delete_deployment">Selecione</p>
        <DeletarDeployment value={deploymentDetail.id}/>
        <Breadcumb path={deploymentPath} onSelect={(id)=>{setCurrentDeployment(id)}}/>


        <Deployments>


            <Breadcumb2 path={[deploymentPath,deployments]} onSelect={(id)=>{setCurrentDeployment(id)}}/>

            <DeploymentNav size={currentDeployment ? 3 : 5} style={currentDeployment ? {width:'60%', flex: 3} : {}} className={currentDeployment ? 'compact' : 'full'} deployments={deployments} selectedDeployment={currentDeployment} onChange={handleSelectDeployment}/>

            {currentDeployment ? <DeploymentDetail>

                <DeploymentName>Detalhes da operação</DeploymentName>
                <DeploymentInfo>
                    <Label>Nome</Label>
                    <Value>{deploymentDetail.nickname || deploymentDetail.alias || ''}</Value>
                </DeploymentInfo>
                <DeploymentInfo>
                    <Label>Endereço 2</Label>
                    <Value>{deploymentDetail.description}</Value>
                </DeploymentInfo>
                <DeploymentsQrCodes>
                    <QRCode>
                        { qrcode1 ? <>
                            <img src={qrcode1} alt="qrcode apontamento" />
                            <LabelQrCode>Apontamento</LabelQrCode>
                        </> : null}
                    </QRCode> :
                    <QRCode>
                        { qrcode2 ? <>
                            <img src={qrcode2} alt="qrcode serviço" />
                            <LabelQrCode>Serviço</LabelQrCode>
                        </> : null}
                    </QRCode>
                </DeploymentsQrCodes>

            </DeploymentDetail> : null}


        </Deployments>
    </Container>
}

export default Imovel;
