import { Container, Link, Logo, Separator, Opener, Background } from './Sidemenu.styles';
import { useHistory, useLocation } from "react-router-dom";
import { useState } from 'react';
import { useEffect } from 'react';

function Sidemenu (props) {
    const [opened, setOpened] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const openMenu = () => {
        setOpened(true);
    }

    const closeMenu = () => {
        setOpened(false);
    }

    const handleOpenItem = (url) => {
        if (url) {
            history.push(url);
        }
    };

    useEffect(()=>{
        if (location) {
            setOpened(false);
        }
    },[location]);

    return <>
        <Opener onClick={()=>{openMenu()}}  />
        <Background className={opened ? 'opened' : ''} onClick={()=>{closeMenu()}} />
        <Container className={'sidemenu' + (opened ? ' opened' : '')}>
            <Link onClick={() => {handleOpenItem(props.home)}}>
                <Logo src={'/app/logo/'+props.logo}></Logo>
            </Link>
            <Separator className={'sidemenu-separator'} />
            {props.children}
        </Container>
    </>
}

export default Sidemenu;