import { Container, CurrentItem, ParentItem} from './Breadcumb2.styles';
import './MyCss.css'

function clear(disp){

    if(document.getElementById('_corpo_'))
        document.getElementById('_corpo_').style.display = 'none';

    if(document.getElementById('_imagem_'))
        document.getElementById('_imagem_').style.display = 'none';

    if(document.getElementById('deploys_item'))
        document.getElementById('deploys_item').style.display = 'flex';

    disp='not-show';

    return null;
}

function Breadcumb2 (props) {

    const handleClick = (value) => {
        if (props.onSelect && typeof props.onSelect === 'function') {
            props.onSelect(value);
        }
    }

    let disp;
    disp='not-show';

    return <Container className={disp}>
        {props.path && props.path.length ? <ParentItem className={disp} onClick={() => {
            handleClick(null)}}>Planta</ParentItem> : <CurrentItem >Planta</CurrentItem>}

        {Boolean(props.path && props.path.length && props.path[0].length > 1) ? (props.path.map((item, index) => {

        let last = props.path[0].length -1;

        if (index > 0 && (props.path[0].length) > 1) {

            if(typeof props.path[0][last] !== 'undefined' && props.path[0][last].blueprint !== null )
            {
                //if(document.getElementById('_corpo_'))
                   // document.getElementById('_corpo_').style.display = 'none';

                if(document.getElementById('deploys_item'))
                    document.getElementById('deploys_item').style.display = 'none';

                disp = 'image-show';

                let image = props.path[0][last].dashboard;

                if(image) {
                    image = image.split("|");
                    if(image.length>1)
                    {
                        return <div class="image-show"><img id="img_planta" src={props.path[0][last].blueprintUrl} key={index} alt="blueprint" /><br /><a key={index+1} href={image[0]} rel="noopener" target="_blank">Link Dashboard Qualidade de Ar</a><br /><a key={index+2} rel="noopener" href={image[1]} target="_blank">Sala de Reunião</a></div>
                    }
                }

                return <div class="image-show"><img id="img_planta" src={props.path[0][last].blueprintUrl} key={index} alt="blueprint" /><br /><a key={index+1} href={image} el="noopener" target="_blank">Link Dashboard Qualidade de Ar</a></div>


            }else return <p key={index+1}><strong key={index}>Não há imagem da planta</strong><br /><br /><a el="noopener" href="#">Link Dashboard Publico</a></p>

        }
        })) : clear()}

    </Container>
}

export default Breadcumb2;
