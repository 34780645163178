import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Icon, Label, Separator } from './SidemenuItem.styles';

export function SidemenuSeparator () {
    return <Separator className={'sidemenu-separator'} />
}

function SidemenuItem (props) {

    const [isSelected, setIsSelected] = useState(false);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        setIsSelected(location.pathname === props.url);
    }, [location, props.url]);

    const handleOpenItem = useCallback((e, props) => {
        if (props.url) {
            history.push(props.url);
        }

        if (props.absoluteUrl) {
            document.location = props.absoluteUrl;
        }

        if (props.onClick && typeof props.onClick === 'function') {
            props.onClick(e);
        }
    }, [history]);

    return <Container className={'sidemenu-item ' + (isSelected ? ' active' : '')} onClick={(e)=>{handleOpenItem(e, props)}}>
        <Icon></Icon>
        <Label>{props.children}</Label>
    </Container>
}

export default SidemenuItem;