import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { updateUserPassword } from '../../../services/users';

import { Row, Label, Password, ModelContent, ButronBar, Button, Error, Info, InfoRow, IsValidIcon } from '../profile.styles';
import Title from '../../../../../components/Title/Title';

function MudarSenha(props) {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmNewPassword, setConfirmNewPassword] = useState('');

    const dispatch = useDispatch();

    const isNewPassEquals = useMemo(()=>{
        return newPassword.length > 0 && String(newPassword) === String(confirmNewPassword);
    }, [newPassword, confirmNewPassword]);

    const isNewPassLength = useMemo(()=>{
        return newPassword.length >= 6;
    }, [newPassword]);

    const isValid = useCallback(() => {
        return isNewPassEquals && isNewPassLength;
    },[isNewPassEquals, isNewPassLength]);

    const handleSave = useCallback(async (id) => {
        if (isValid()) {
            setIsLoading(true);

            // let res = await updateUser(props.id, {
            //     currentPassword,
            //     newPassword,
            //     confirmNewPassword
            // });

            let res = await updateUserPassword(props.id, {
                currentPassword,
                newPassword,
                confirmNewPassword
            });

            setIsLoading(false);

            if (res.toast && res.toast.type !== 'success') {
                setError(res.toast.message)
                return
            } else {
                setError(null)
            }

            dispatch(modalActions.setState('closed'))
        }
    }, [dispatch, props, currentPassword, newPassword, confirmNewPassword, isValid]);

    return <>
        <Title className={'modal'}>Mudar Senha</Title>
        <ModelContent>
            <Row>
                <Label>Senha atual</Label>
                <Password value={currentPassword} onChange={(e)=>{setCurrentPassword(e.target.value)}}/>
            </Row>
            <Row>
                <Label>Nova senha</Label>
                <Password value={newPassword} onChange={(e)=>{setNewPassword(e.target.value)}}/>
            </Row>
            <Row>
                <Label>Confirmar nova senha</Label>
                <Password value={confirmNewPassword} onChange={(e)=>{setConfirmNewPassword(e.target.value)}}/>
            </Row>
            <Info>
                <InfoRow>
                    Para realizar a troca de senha, os seguintes critérios devem ser atendidos:
                </InfoRow>
                <InfoRow>
                    <IsValidIcon className={isNewPassLength ? 'valid' : ''}/>
                    <span>As senhas devem ter mais de 6 caracteres</span>
                </InfoRow>
                <InfoRow>
                    <IsValidIcon className={isNewPassEquals ? 'valid' : ''}/>"Nova senha" e "Confirmar nova senha" devem ser iguais
                </InfoRow>
            </Info>
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button className={(isLoading ? 'loading-spinner-btn' : '') + (isValid() ? '' : ' deactive')} onClick={()=>{handleSave()}}>Salvar</Button>
        </ButronBar>
    </>
}

export default MudarSenha;
