import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 300px;
    right: 0;
    height: 70px;
    z-index: 99;
    border-bottom: 1px solid #cacaca;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 900px) {
        & {
            left: 0;
        }
    }
`;

export const SelectorClient = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
`;

export const Label = styled.div`
    font-size: 16px;
    color: #333;
    margin: 0 20px 0 0;

    @media only screen and (max-width: 900px) {
        & {
            margin:0 5px 0 25px;
            font-size: 14px;
        }
    }
`;

export const Select = styled.div`
    width: 300px;

    @media only screen and (max-width: 900px) {
        & {
            width: 130px;
        }
    }
`;

export const Amihub = styled.a`
    width: 50px;
    height: 50px;
    margin: 10px 20px;
    overflow: hidden;
    text-decoration: none;

    @media only screen and (max-width: 900px) {
        & {
            margin: 10px 20px 10px 0;
        }
    }
`;