import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, ButronBar, Row, RowInline, Label, Input, Select, Button, Tabs, Tab } from './config.styles';

import Title from '../../../../components/Title/Title';
import Tipos from './modal/tipos';

import { configActions, modalActions } from '../../../../redux/actions';
import { getAppConfig, updateAppConfig } from '../../services/config';

const tabs = [
    {id:'assets', label: "Ativos" }
]

function Config () {

    const [tab, setTab] = useState('assets');
    const [tipoAtivo, setTipoAtivo] = useState('');
    const [currentForms, setCurrentForms] = useState('');
    const [currentConfig, setCurrentConfig] = useState('');
    
    const assetTypes = useSelector(state => state.configState.app_asset_types);
    const assetForms = useSelector(state => state.configState.app_asset_forms);
    const assetConfig = useSelector(state => state.configState.app_asset_config);
    const dispatch = useDispatch();

    const assetTypesArray = useMemo(()=>{
        let assetKeys = Object.keys(assetTypes);
        return assetKeys.map(t => ({id: t, label: assetTypes[t]}));
    },[assetTypes]);
    
    const getData = useCallback(async () => {
        try {
            let cfg = await getAppConfig();

            if (cfg && cfg.payload && cfg.payload.objects && cfg.payload.objects[0] && cfg.payload.objects[0].config) {
                let config = cfg.payload.objects[0].config;
                if (config && config.private && config.private.appId) {
                    dispatch(configActions.setAppId(config.private.appId));
                }
                if (config && config.public && config.public.assets && config.public.assets.asset_types) {
                    dispatch(configActions.setAppAssetTypes(config.public.assets.asset_types));
                }
                if (config && config.public && config.public.assets && config.public.assets.asset_forms) {
                    dispatch(configActions.setAppAssetForms(config.public.assets.asset_forms));
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [dispatch]);

    const handleOpenTypes = useCallback(async () => {
        dispatch(modalActions.setState('opened'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Tipos />));
    }, [dispatch]);

    useEffect(() => {
        getData();
    }, [getData]);

    useEffect(() => {
        setCurrentForms(assetForms);
    }, [assetForms]);

    useEffect(() => {
        console.log(assetConfig);
        setCurrentConfig(assetConfig);
    }, [assetConfig]);

    useEffect(() => {
        let createConfig = {};
        for(let type in assetTypes) {
            if (assetTypes.hasOwnProperty(type) && !assetConfig[type]) {
                createConfig[type] = {
                    "frequencia_limpeza": ""
                };
            }
        }

        if (Object.keys(createConfig).length > 0) {
            setCurrentConfig({
                ...assetConfig,
                ...createConfig
            });
        }

    }, [assetConfig, assetTypes]);

    const handleSave = () => {
        dispatch(configActions.setAppAssetForms(currentForms))
        updateAppConfig({assets: {
            asset_forms: currentForms,
            asset_types: assetTypes,
            asset_config: currentConfig,
        }});
    };

    const handleNewField = () => {
        if (!tipoAtivo) {
            return '';
        }

        let form = currentForms ? currentForms[tipoAtivo] || [] : [];
        

        let newForm = [...form];
        newForm.push({
            id: (new Date()).getTime(),
            name: '',
            label: '',
            type: 'input',
            default: '',
            options: [],
        });

        setCurrentForms({...currentForms, [tipoAtivo]: newForm});
    };

    const handleRemoveField = (id) => {
        if (!tipoAtivo) {
            return '';
        }

        let form = currentForms ? currentForms[tipoAtivo] || [] : [];
        
        let newForm = [...form].filter(f => f.id !== id);
        setCurrentForms({...currentForms, [tipoAtivo]: newForm});
    };

    const setFormField = (key, field, value) => {
        let form = currentForms ? currentForms[tipoAtivo] || null : null;
        
        if (!form) {
            return '';
        }

        let newForm = [...form].map(f => {
            let item = {...f};
            if (item.id === key) {
                if (field !== 'options') {
                    item[field] = value;
                } else {
                    item[field] = String(value).split(',').map(i=>String(i).trim());
                }
            }

            return item;
        });

        setCurrentForms({...currentForms, [tipoAtivo]: newForm});
    };

    const getFormField = (key, field) => {
        let form = currentForms ? currentForms[tipoAtivo] || null : null;
        
        if (!form) {
            return '';
        }

        let obj = form.filter(f => f.id === key)[0];

        let val = obj[field];
        if (!val) {
            return '';
        }
        if (typeof val === 'object' && typeof val.length !== 'undefined') {
            return val.join(',');
        }
        return val || '';
    };

    const getConfigValue = (key) => {
        if (!tipoAtivo || !currentConfig[tipoAtivo]) {
            return '';
        }

        return currentConfig[tipoAtivo][key] ?? '';
    }

    const setConfigValue = (key, value) => {
        if (!tipoAtivo) {
            return;
        }

        setCurrentConfig(state => ({
            ...state,
            [tipoAtivo]: Object.assign({}, state[tipoAtivo] ?? {}, {[key]: value})
        }))
    }

    const renderAssetForm = () => {
        if (!tipoAtivo) {
            return null;
        }

        let form = currentForms[tipoAtivo] || [];

        let result = [<Row key={'header'}>
            <Label>Campo</Label>
            <Label>Label</Label>
            <Label>Tipo</Label>
            <Label>Valor Padrão</Label>
            <Label>Opções</Label>
            <Label className={'op'}></Label>
        </Row>];

        for(let f of form) {
            result.push(<Row key={f.id}>
                <Label className={'col'}><Input value={getFormField(f.id, 'name')} onChange={(e)=>{setFormField(f.id, 'name', e.target.value)}} /></Label>
                <Label className={'col'}><Input value={getFormField(f.id, 'label')} onChange={(e)=>{setFormField(f.id, 'label', e.target.value)}} /></Label>
                <Label className={'col'}><Select value={getFormField(f.id, 'type')} onChange={(e)=>{setFormField(f.id, 'type', e.target.value)}}>
                    <option value="input">Input</option>
                    <option value="select">Select</option>
                    <option value="hidden">Hidden</option>
                </Select></Label>
                <Label className={'col'}><Input value={getFormField(f.id, 'default')} onChange={(e)=>{setFormField(f.id, 'default', e.target.value)}} /></Label>
                <Label className={'col'}><Input value={getFormField(f.id, 'options')} onChange={(e)=>{setFormField(f.id, 'options', e.target.value)}} /></Label>
                <Label className={'op'}>
                    <Button className={'compact op'} onClick={()=>handleRemoveField(f.id)} color="#f33">x</Button>
                </Label>
            </Row>);
        }

        result.push(<Button key={'add'} className={'large mc'} onClick={()=>handleNewField()} color="#39f">Adicionar campo</Button>);

        return result;
    }

    const renderAssetConfig = () => {
        if (!tipoAtivo) {
            return null;
        }
        
        return <Row>
            <Label>Recorrência de atividades de higienização/manutenção</Label>
            <RowInline>
                <Select value={getConfigValue('frequencia_limpeza')} onChange={e => setConfigValue('frequencia_limpeza', e.target.value)}>
                    <option value=""></option>
                    <option value="1">1 mês</option>
                    <option value="2">2 meses</option>
                    <option value="3">3 meses</option>
                    <option value="6">6 meses</option>
                    <option value="12">12 meses</option>
                </Select>
            </RowInline>
        </Row>
    }

    return <Container>
            <Title>Configurações</Title>
            <Tabs>
                {tabs.map(t => (
                    <Tab key={t.id} className={tab===t.id ? 'active' : ''} onClick={()=>{setTab(t.id)}}>{t.label}</Tab>
                ))}
            </Tabs>
            {tab === 'assets' ? <>
                <Row>
                    <Label>Tipos de Ativos</Label>
                    <RowInline>
                        <Select value={tipoAtivo} onChange={e => setTipoAtivo(e.target.value)}>
                            <option value=""></option>
                            {assetTypesArray && assetTypesArray.length ? assetTypesArray.map(t => (
                                <option key={t.id} value={t.id}>{t.label}</option>
                            )) : null}
                        </Select>
                        <Button className={'large compact ml'} onClick={()=>{handleOpenTypes()}}>Adicionar Classes</Button>
                    </RowInline>
                </Row>
                { renderAssetConfig() }
                { renderAssetForm() }
            </> : null}
            <ButronBar>
                <Button onClick={()=>{handleSave()}}>Salvar</Button>
            </ButronBar>
    </Container>
}

export default Config;