import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;


export const getUsers = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/data/user', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getUserData = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/data/user/'+id, {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postUser = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/data/user/', {
            payload: {
                objects: {
                    user: data
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const updateUser = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/data/user/', {
            payload: {
                objects: [
                    {
                        id,
                        ...data
                    }
                ]
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};


export const updateUserPassword = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/data/user/newpassword', {
            payload: {
                objects: [
                    {
                        id,
                        ...data
                    }
                ]
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const deleteUser = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/milliclick/data/user/', {
            data: {
                payload: {
                    objects: {
                        id
                    }
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
