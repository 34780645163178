import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions, configActions } from '../../../../../redux/actions';

import { Row, Label, Input, ButronBar, Button, ModelContent } from '../config.styles';
import Title from '../../../../../components/Title/Title';

function Tipos() {

    const [types, setTypes] = useState([]);
    
    const assetTypes = useSelector(state => state.configState.app_asset_types);
    const dispatch = useDispatch();

    useEffect(()=>{
        let assetKeys = Object.keys(assetTypes);
        setTypes(assetKeys.map(t => ({id: t, label: assetTypes[t]})));
    },[assetTypes]);

    const handleSalvar = () => {
        let result = {};
        for (let t of types) {
            result[t.id] = t.label;
        };

        dispatch(modalActions.setState('closed'))
        dispatch(configActions.setAppAssetTypes(result))
    }

    const handleAddType = () => {
        setTypes(t => {
            let newTypes = [...t];
            let key = (new Date()).getTime();
            newTypes.push({id: key, label: ''});
            return newTypes;
        })
    }

    const handleRemoveype = (id) => {
        setTypes(types => types.filter(t=>t.id !== id));
    }

    const handleTypeNameChange = (id, value) => {
        setTypes(state => {
            let types = [...state];

            types.map(t => {
                if (t.id === id) {
                    t.label = value;
                }

                return t;
            });


            return types;
        });
    };

    return <>
        <Title className={'modal'}>Tipos de Ativos</Title>
        <ModelContent>
            <Label>Informe os tipos de ativos utilizados</Label>
            {types && types.length ? types.map(type => (
                <Row key={type.id}>
                    <Input value={type.label} onChange={(e)=>handleTypeNameChange(type.id, e.target.value)}/>
                    <Button className={'compact ml'} onClick={()=>handleRemoveype(type.id)} color="#f33">Remover</Button>
                </Row>
            )) : null}
            <Button className={'large mc'} onClick={()=>handleAddType()} color="#39f">Adicionar tipo</Button>
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Tipos;