import { useState, memo, useMemo, useCallback, useEffect } from 'react';
import { Container, Row, Label, Input } from './Form.styles';

function FormCPF (props) {
    const [dataCPF, setDataCPF] = useState('');
    const [dataEndereco, setDataEndereco] = useState('');
    const [dataNome, setDataNome] = useState('');
    const [dataEmail, setDataEmail] = useState('');

    const isValidCPF = useMemo(() => {
        if (!dataCPF) {
            return false;
        }

        // https://gist.github.com/joaohcrangel/8bd48bcc40b9db63bef7201143303937
        let sum;
        let rest;
        sum = 0;
        if (dataCPF === "00000000000") return false;

        for (let i=1; i<=9; i++) sum = sum + parseInt(dataCPF.substring(i-1, i)) * (11 - i);
        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11))  rest = 0;
        if (rest !== parseInt(dataCPF.substring(9, 10)) ) return false;

        sum = 0;
        for (let i = 1; i <= 10; i++) sum = sum + parseInt(dataCPF.substring(i-1, i)) * (12 - i);
        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11))  rest = 0;
        if (rest !== parseInt(dataCPF.substring(10, 11) ) ) return false;
        return true;
    }, [dataCPF]);

    const isValidEmail = useMemo(() => {
        if (dataEmail) {
            return true;
        } else {
            return false;
        }
    }, [dataEmail]);

    const formatCPF = useMemo(() => {
        if (dataCPF.length <= 3) {
            return dataCPF;
        } else if (dataCPF.length <= 6) {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3);
        } else if (dataCPF.length <= 9) {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3) + '.' + dataCPF.substr(6,3);
        } else {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3) + '.' + dataCPF.substr(6,3) + '-' + dataCPF.substr(9,2);
        }
    }, [dataCPF]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                cpf: dataCPF,
                endereco: dataEndereco,
                nome: dataNome,
                email: dataEmail
            }, isValidCPF && dataEndereco && dataNome && isValidEmail);
        }
    }, [props, isValidCPF, dataCPF, isValidEmail, dataEndereco, dataNome, dataEmail])

    const handleCPF = useCallback((val) => {
        setDataCPF(val.replace(/[^\d]/gi, ''));
    }, []);

    return <Container>
        <Row>
            <Label>CPF</Label>
            <Input value={formatCPF} onChange={e=>handleCPF(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Endereço</Label>
            <Input value={dataEndereco} onChange={e=>setDataEndereco(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Nome</Label>
            <Input value={dataNome} onChange={e=>setDataNome(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Email</Label>
            <Input value={dataEmail} onChange={e=>setDataEmail(e.target.value)} type="text" />
        </Row>
    </Container>
}

export default memo(FormCPF);
