import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    padding: 0 0 20px 0;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    margin-bottom: 10px;
    box-sizing: border-box;
`;

export const Input = styled.input.attrs(()=>({
    type: 'text'
}))`
    flex: 1;
    border: 1px solid #cacaca;
    height: 30px;
    padding: 0 5px;
    box-sizing: border-box;
`;

export const Hidden = styled.input.attrs(()=>({
    type: 'hidden'
}))``;

export const Select = styled.select`
    flex: 1;
    border: 1px solid #cacaca;
    height: 30px;
    padding: 0 5px;
    box-sizing: border-box;
`;

export const Label = styled.div.attrs(props=>({
    style: {
        flex: props.flex || 1,
    }
}))`
    flex: 1;
    color: #333;
`;

export const Value = styled.div.attrs(props=>({
    style: {
        flex: props.flex || 1,
    }
}))`
    font-weight: 900;
    color: #333;
`;