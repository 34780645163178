import { useCallback, useMemo, useState } from 'react';
import { Container, Header, ColumnHeader, Row, Column } from './Table.styles';

function Table (props) {

    const [orderByField, setOrderByField] = useState('id');
    const [orderByDirection, setOrderByDirection] = useState('asc');

    const handleOrderBy = useCallback((key) => {
        let orderDirection = 'asc';

        if (orderByField === key) {
            orderDirection = orderByDirection === 'asc' ? 'desc' : 'asc';
        }

        setOrderByField(key);
        setOrderByDirection(orderDirection);

        if (props.onOrderBy && typeof props.onOrderBy === 'function') {
            props.onOrderBy(key, orderDirection);
        }
        //
    }, [orderByField, orderByDirection, props]);

    const renderHeader = useMemo(() => {
        return props.columns && props.columns.length ? props.columns.map(item => (
            <ColumnHeader key={item.key} width={item.width || null} className={orderByField === item.key ? orderByDirection : ''} onClick={()=>handleOrderBy(item.key)}>{item.label}</ColumnHeader>
        )) : null;
    }, [props.columns, orderByDirection, orderByField, handleOrderBy]);

    const renderRows = useMemo(() => {

        return props.data && props.data.length ? props.data.map(row => (
            <Row key={row.id} className={'tablerow'}>
                {props.columns && props.columns.length ? props.columns.map(column => (
                     <Column key={column.key} width={column.width || null}>{column.adapter ? column.adapter(row[column.key], row) : (row[column.key] || '')}</Column>
                )) : null}
            </Row>
        )) : null;

    }, [props.data, props.columns]);

    console.log(props);

    return <Container>
        <Header>{renderHeader}</Header>
        {renderRows}
    </Container>
}

export default Table;
