import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { postFile } from '../../../services/files';

import { Row, Label, Input, ButronBar, Button, ModelContent, ErrorMsg } from '../arquivos.styles';
import Title from '../../../../../components/Title/Title';
import { UploadLabel, UploadItem, Select} from '../../forms/Form.styles';

function Upload(props) {
    const [formNome, setFormNome] = useState('');
    const [formArquivo, seFormArquivo] = useState('');
    const [formTag, setFormTag] = useState('Relatório de Microbiologia');
    const [error, setError] = useState('');

    const dispatch = useDispatch();

    const fileName = useMemo(() => {
        return formArquivo ? formArquivo[0].name : 'Nenhum arquivo selecionado';
    }, [formArquivo]);

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        let success = await postFile({
            name: formNome,
            file: formArquivo,
            tag: formTag,
            deployment_id: props.deployment_id ?? null,
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
            } else {
                setError('');
                dispatch(modalActions.setState('close'));
                if (typeof props.onRefresh === 'function') {
                    props.onRefresh();
                }
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, formNome, formArquivo, formTag]);

    return <>
        <Title className={'modal'}>Enviar arquivo</Title>
        <ModelContent>
            <Row>
                <Label>Nome do arquivo</Label>
                <Input value={formNome} onChange={(e)=>setFormNome(e.target.value)}/>
            </Row>
            <Row>
                <Label>Arquivo</Label>
                <UploadLabel htmlFor="data-file">{fileName}</UploadLabel>
                <UploadItem id="data-file" accept="application/pdf" onChange={e=>seFormArquivo(e.target.files)} />
            </Row>
            <Row>
                <Label>Identificação</Label>
                <Select value={formTag} onChange={(e)=>setFormTag(e.target.value)}>
                    <option value="Relatório de Microbiologia">Relatório de Microbiologia</option>
                    <option value="Outros">Outros</option>
                </Select>
            </Row>
        </ModelContent>
        {error ? <ErrorMsg>{error}</ErrorMsg> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Upload;
