import styled from "styled-components"; 

export const FilterDeployment = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const LabelDeployment = styled.div`
    font-size: 12px;
`;

export const SelectDeployment = styled.div`
    width: 350px;
    margin-bottom: 20px;
`;