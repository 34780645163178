import styled from "styled-components"; 

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`;

export const Label = styled.div`
    font-size:12px;
    color: #000;
    margin-bottom: 5px;
`;

export const Items = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const Item = styled.div`
    margin-right: 10px;
    padding: 4px 10px;
    color: #333;
    border: 1px solid #333;
    cursor: pointer;

    &.active {
        background-color: #333;
        color: #fff;
    }
`;