import styled from 'styled-components';

export const Container = styled.div`
    width: 60vw;
    background-color: #fff;
    position: fixed;
    top: -500%;
    left: -500%;
    z-index: 99999;
    transform: translate(-30vw, -50%);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    &.loading,
    &.sent,
    &.opened {
        top: 50%;
        left: 50%;
    }

    &.normal {
        transform: translate(-30vw, -50%);
        height: 90vh;
    }

    &.sent:before,
    &.loading:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #fff;
        z-index: 99999;
    }
    &.sent:after,
    &.loading:after {
        position: absolute;
        top: 50%;
        left: 50%;
        margin:-10px 0 0 -10px;
        width: 20px;
        height: 20px;
        border: 3px solid transparent;
        border-top: 3px solid #333;
        border-left: 3px solid #333;
        border-radius: 20px;
        background-color: #fff;
        z-index: 99999;
        content: '';
        animation: spinner-loading 1s linear infinite;
    }
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 100%;
    z-index: 99998;
    background-color: rgba(0,0,0,0.6);

    &.loading,
    &.sent,
    &.opened {
        bottom: 0;
    }
`;