import { deleteDeployment2 } from '../../apps/milliclick/services/deployments';
import './DeletarDeployment.css';

function DeletarDeployment(props) {

    const onDelete = (value) => {

        if(typeof value !== 'undefined')
        {
            (async () => {
                try {

                    let res = await deleteDeployment2(value);
                    if(res){

                        if(res.toast.type === 'error')
                        {
                            if(res.validator[0].error === '401')
                                document.getElementById('status_delete_deployment').innerHTML = 'Usuário sem permissões de remoção!';

                            document.getElementById("fechar").click();
                        }else{
                            document.getElementById('status_delete_deployment').innerHTML = 'Operação excluída!';
                            window.location.href = "http://milliclick.ami-hub.local/app/milliclick/imovel";
                        }
                    }else{
                        document.getElementById("fechar").click();
                        document.getElementById('status_delete_deployment').innerHTML = 'Não foi possível excluir a operação!';
                    }

                } catch (e) {console.error(e);}})();
        }else{
            document.getElementById('status_delete_deployment').innerHTML = 'Selecione uma operação!';
        }
    }

    return <>

        <a href="#abrirModal" className="aforbtn">Deletar Operação</a>

        <div id="abrirModal" className="modal">
            <div id="centerdiv">
                <a href="#fechar" title="Fechar" className="fechar" id="fechar">x</a>
                <h2>Atenção</h2>
                <p>Você realmente deseja excluir esta operação?</p>
                <button className="btn_delete_deployment" key={props.id} onClick={() => {onDelete(props.value)}}>Sim</button>
            </div>
        </div>

    </>

}
export default DeletarDeployment;
