
import { ModalTypes } from '../constants/modalConstants';

const initialState = {
    state: 'closed',
    content: null,
    size: 'normal',
    loading: [],
};

export const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case ModalTypes.SET_MODAL_STATE:
            return {
                ...state,
                state: action.state
            };

        case ModalTypes.SET_MODAL_CONTENT:
            return {
                ...state,
                content: action.content
            };

        case ModalTypes.SET_MODAL_SIZE:
            return {
                ...state,
                size: action.size
            };

        case ModalTypes.SET_LOADING:
            return {
                ...state,
                loading: action.loading ? [...state.loading, action.key] : state.loading.filter(i=>i!==action.key)
            };
        
        case 'RESET':
            return {...initialState};
        
        default:
            return state;
    }
};