import { useState, useCallback, useEffect, useMemo } from 'react';
import { Container, Acoes, BtnAcao, Tag } from './arquivos.styles';
import { useSelector, useDispatch } from 'react-redux';

import { modalActions, deploymentsActions } from '../../../../redux/actions';
import { getFiles, downloadFile } from '../../services/files';
import { getDeployments } from '../../services/deployments';

import Title from '../../../../components/Title/Title';
import Subtitle from '../../../../components/Subtitle/Subtitle';
import Table from '../../../../components/Table/Table';
import Actionbar from '../../../../components/Actionbar/Actionbar';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import FilterDeployment from '../../../../components/FilterDeployment/FilterDeployment';

import Upload from './modal/upload';
import Remover from './modal/remover';

function Arquivos () {

    const permissionsState = useSelector(state=> state.permissionsState.permissions);
    const [filesList, setFilesList] = useState([]);
    const [filterDeployment, setFilterDeployment] = useState(null);
    const [lastFilesList, setLastFilesList] = useState([]);
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});
    const clientId = useSelector(state=> state.permissionsState.clientId);
    const dispatch = useDispatch();

    const getListing = useCallback(async () => {
        try {
            let files = await getFiles({order_field:orderBy.orderBy, order:orderBy.orderDir, deployment_id: filterDeployment});
            if (files && files.payload && files.payload.objects){
                setFilesList(files.payload.objects.map(i=>i.file));
            }

            let last = await getFiles({order_field:'created_at', order:'desc', count:3, page:1});
            if (last && last.payload && last.payload.objects){
                setLastFilesList(last.payload.objects.map(i=>i.file));
            }
        } catch (e) {
            console.error(e);
        }
    }, [orderBy, filterDeployment]);

    useEffect(() => {
        if (clientId) {
            getListing();
        }
    }, [getListing, clientId]);

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    }

   const handleModalUpload = useCallback((id) => {
        dispatch(modalActions.setState('opened'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Upload id={id} deployment_id={filterDeployment} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, filterDeployment]);

   const handleBaixar = useCallback((id, name, extension) => {
        downloadFile(id, name, extension);
    }, []);

   const handleRemover = useCallback((id) => {
    dispatch(modalActions.setState('loading'));
    dispatch(modalActions.setSize('compact'));
    dispatch(modalActions.setContent(<Remover id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const columns = useMemo(() => ([
        {key:'name', label:'Nome'},
        {key:'extension', label:'Extensão', adapter: (val) => 'Arquivo ' + String(val).toUpperCase()},
        {key:'created_at', label:'Criado em'},
        {key:'tags', label:'Identificação', adapter: (val) => val ? (String(val).split('|').map((tag,i)=><Tag key={i}>{String(tag).trim()}</Tag>)) : ''},
        {key:'actions', label:'Ações', width: 80, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleBaixar(row.id, row.name, row.extension)} icon={"\\F01DA"} title="Baixar"/>
            {permissionsState.manager === true ? <BtnAcao onClick={() => handleRemover(row.id)} icon={"\\F01B4"} title="Remover"/> : null}
        </Acoes>)}
    ]),[handleBaixar, handleRemover, permissionsState.manager]);

    const columnsLast = useMemo(() => ([
        ...columns.filter(i=>i.key !== 'actions'),
        {key:'actions', label:'Ações', width: 40, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleBaixar(row.id, row.name, row.extension)} icon={"\\F01DA"} title="Baixar"/>
        </Acoes>)}
    ]),[columns, handleBaixar]);

    const handleFilterDeployment = (deployment_id) => {
        setFilterDeployment(deployment_id);
    };

    useEffect(() => {
        (async () => {
            try {
                let res = await getDeployments();
                let deployments = res.payload.objects.map(i=>i.deployment)
                if (deployments && deployments.length) {
                    dispatch(deploymentsActions.setDeployments(deployments));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [dispatch]);

    return <Container>
        <Title>Repositório de Arquivos</Title>
        <FilterDeployment value={filterDeployment} onChange={(v)=>{handleFilterDeployment(v)}} />
        {permissionsState.manager === true ? <Actionbar>
            <ActionButton onClick={handleModalUpload}>Fazer upload</ActionButton>
        </Actionbar> : null}
        <Table
            onOrderBy={handleOrderBy}
            columns={columns}
            data={filesList}
        />
        <Subtitle>Arquivos recentes</Subtitle>
        <Table
            columns={columnsLast}
            data={lastFilesList}
        />
    </Container>
}

export default Arquivos;
