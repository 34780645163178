import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getAppConfig = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/app', {params: {
            sourceId: 7,
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const updateAppConfig = async (publicItems) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/resource/app', {params: {
            sourceId: 7,
            public: publicItems
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
