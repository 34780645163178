import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getAssetHistory } from '../../../services/assets';

import { HistoryItem, ModelContent, ButronBar, Button } from '../ativos.styles';
import Title from '../../../../../components/Title/Title';

function Historico(props) {
    const [items, setItems] = useState([]);

    const modalState = useSelector(state => state.modalState.state)

    const dispatch = useDispatch();

    useEffect(()=>{
        (async () => {
            let historyData = await getAssetHistory(props.id);
            let items = [];
            if (historyData.payload.objects) {
                items = historyData.payload.objects.map(i=>i.asset);
            }

            if (modalState === 'loading') {
                setItems(items, props.id);
                dispatch(modalActions.setState('opened'));
            }
        })();
    }, [dispatch, props.id, modalState]);

    return <>
        <Title className={'modal'}>Histórico</Title>
        <ModelContent>
            {(items && items.length) ? items.map((item, index) => (
                <HistoryItem key={index}>{item.userName} realizou atividade {item.description} em {item.eventDate}</HistoryItem>
            )) : null}
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
        </ButronBar>
    </>
}

export default Historico;
