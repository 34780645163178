import styled from 'styled-components';

export const Container = styled.div`
    margin-right: 10px;
    padding: 4px 10px;
    color: #333;
    border: 1px solid #333;
    cursor: pointer;
    transition: all .3s ease 0s;

    &:hover {
        background-color: #333;
        color: #fff;
    }
`;