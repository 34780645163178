import { memo, useEffect, useMemo, useState, useRef } from 'react';
import { Container, Background, SelectedValue, FilterBy, Items, Item } from './SelectFilter.styles';

function SelectFilter (props) {
    const [opened, setOpened] = useState(false);
    const [filter, setFilter] = useState('');
    const [value, setValue] = useState('');

    const refItem = useRef(null);
    const refWrapper = useRef(null);

    const handleFilter = (e) => {
        setFilter(e.target.value);
    }

    const handleSelect = (v, e) => {
        setOpened(false);
        setValue(v);
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(v, e);
        }
    }

    const valueLabel = useMemo(()=>{
        if (props.items && props.items.length) {
            for(let i of props.items) {
                if (i.value === value) {
                    return i.label;
                }
            }
        }

        return '';
    },[props.items, value]);

    useEffect(()=>{
        let pos = {};
        let relPos = {};
        let scrollPos = {};

        if (opened) {
            pos = refItem.current.getBoundingClientRect();
            relPos = refItem.current.offsetParent.getBoundingClientRect();
            scrollPos = [refItem.current.offsetParent.scrollLeft, refItem.current.offsetParent.scrollTop];

            refWrapper.current.style.top = (Math.floor(pos.top - relPos.top) + 40 + scrollPos[1])+'px' || '-50%';
            refWrapper.current.style.left = (Math.floor(pos.left - relPos.left + scrollPos[0])+'px') || '-50%';
            refWrapper.current.style.width = (Math.floor(pos.width + 3)+'px') || '0';
            refWrapper.current.style.position = 'absolute';
        } else {
            refWrapper.current.style.top = '-50%';
            refWrapper.current.style.left = '-50%';
            refWrapper.current.style.width = '0';
            refWrapper.current.style.position = 'fixed';
        }
    },[opened]);

    useEffect(()=>{
        setValue(v => v !== props.value ? props.value : v);
    },[props.value]);

    return <>
        <Background className={opened ? 'opened' : ''} onClick={()=>setOpened(false)}/>
        <SelectedValue width={props.width || '100%'} ref={refItem} onClick={()=>setOpened(true)}>{valueLabel}</SelectedValue>
        <Container ref={refWrapper}>
            <FilterBy placeholder="Filtrar por..." value={filter} onChange={handleFilter} />
            <Items>
                {(props.items && props.items.length) ? props.items.filter(i=>(new RegExp(filter,'gi')).test(i.label)).map(item => (
                    <Item key={item.value} onClick={(e)=>{handleSelect(item.value, e)}} value={item.value}>{item.label}</Item>
                )) : null}
            </Items>
        </Container> 
    </>
}

export default memo(SelectFilter);