import styled from 'styled-components';

export const Container = styled.div`
    width: 200px;
    height: 200px;
    background-color: #333;
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
`;
export const Value = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 70px;
`;

export const Label = styled.div`
position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
    height: 30px;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
`;
