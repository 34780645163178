import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getWorkOrderData, updateWorkOrderDataID } from '../../../services/workorder';
import { getUsers } from '../../../services/users';

import { Row, Label, ButronBar, Button, ModelContent, Error } from '../servicos.styles';
import Title from '../../../../../components/Title/Title';
import Multiselect from '../../../../../components/Multiselect/Multiselect';

function Reatribuir(props) {
    const [item, setitem] = useState('');
    const [formAtribuido, setFormAtribuido] = useState([]);
    const [users, setUsers] = useState([]);
    const [error, setError] = useState('');

    const modalState = useSelector(state => state.modalState.state)
    const dispatch = useDispatch();

    useEffect(() => {
        if (item) {
            setError('');
            setFormAtribuido(item.wo_assignee || []);
            dispatch(modalActions.setState('opened'));
        }
    }, [item, dispatch]);

    useEffect(() => {
        (async () => {
            try {
                let res = await getUsers();

                if (res && res.payload && res.payload.objects){
                    let usersList = res.payload.objects.map(i=>i.user);
                    if (usersList.length) {
                        usersList = usersList.map(user=> ({
                            value: user.id,
                            label: user.nome
                        }));
                    }
                    setUsers(usersList);
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, []);

    useEffect(()=>{
        (async () => {
            if (modalState === 'loading') {
                let editData = await getWorkOrderData(props.id);
                if (editData.payload.objects) {
                    setitem((editData.payload.objects[0] || {}).workorder || null);
                }

            }
        })();
    }, [dispatch, props.id, modalState]);

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        // let success = await updateWorkOrderData(props.id, {
        //     wo_assignee: formAtribuido
        // });

        let success = await updateWorkOrderDataID(props.id, {
            wo_assignee: formAtribuido
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
            } else {
                setError('');
                dispatch(modalActions.setState('close'));
                if (typeof props.onRefresh === 'function') {
                    props.onRefresh();
                }
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, formAtribuido]);

    return <>
        <Title className={'modal'}>Reatribuir Serviço</Title>
        <ModelContent>
        {(item) ? <>
            <Row>
                <Label>Atribuído a</Label>
                <Multiselect items={users} onChange={(v)=>setFormAtribuido(v)} value={formAtribuido} />
            </Row>
        </> : null}
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Reatribuir;
