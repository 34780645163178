import styled, {createGlobalStyle} from 'styled-components';

export const Content = styled.div`
    position: absolute;
    top: 70px;
    left: 300px;
    right: 0;
    bottom: 0;
    overflow: auto;

    @media only screen and (max-width: 900px) {
        & {
            left: 0;
        }
    }
`;

export const GlobalStyle = createGlobalStyle`
    @keyframes loading-spinner {
        from {transform: rotate3d(0, 0, 1, 0deg)}
        to {transform: rotate3d(0, 0, 1, 360deg)}
    }

    .loading-spinner-btn {
        position: relative;
    }

    .loading-spinner-btn:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255,255,255, 0.8);
        z-index: 2;
    }

    .loading-spinner-btn:after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 20px;
        height: 20px;
        margin: -10px 0 0 -10px;
        border: 3px solid transparent;
        border-top: 3px solid #000;
        border-left: 3px solid #000;
        border-radius: 10px;
        box-sizing: border-box;
        background-color: rgba(255,255,255, 0.8);
        z-index: 3;
        animation: loading-spinner linear forwards infinite 1s;
    }

    .input-calendar {
        width: 100%;
        height: 30px;
        border: 1px solid #cacaca;
        padding: 0 5px;
        box-sizing: border-box;
    }
`;