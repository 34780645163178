export const propertiesValues = {
    tipo: {
        cadeira: "Cadeira",
        sofa: "Sofá",
        outro: "Outra mobília"
    },
    cor: {
        bege: "Bege",
        branco: "Branco",
        cinza: "Cinza",
        preto: "Preto",
        outro: "Outra cor"
    },
    assento: {
        tela: "Tela",
        tecido: "Tecido",
        couro: "Couro"
    },
    encosto: {
        tela: "Tela",
        tecido: "Tecido",
        couro: "Couro"
    },
    limpezaTipo: {
        "day": "dias",
        "month": "meses",
        "year": "anos",
        "never": "Nunca"
    }
};

export function getProperty (key, properties) {
    if (!properties || !Array.isArray(properties)) {
        return null;
    }

    for (let prop of properties) {
        if (prop.key === key) {
            return prop.value;
        }
    }

    return null;
}

export function setProperty (key, value, properties) {
    let newProps = [...properties];

    if (!properties || !Array.isArray(properties)) {
        return newProps;
    }

    for (let i in properties) {
        if (properties[i].key === key) {
            newProps[i] = {key, value};
            return newProps;
        }
    }

    properties.push({key, value});

    return properties;
}

export function propsReducer (state, action) {
    let newProps = [...state];

    switch (action.type) {
        case 'clean': 
            return [];
        case 'set':
            if (!action.payload || !action.payload.key) {
                return state;
            }
        
            for (let i in newProps) {
                if (newProps[i].key === action.payload.key) {
                    newProps[i].value = action.payload.value;
                    return newProps;
                }
            }
        
            newProps.push({key: action.payload.key, value: action.payload.value || null});
        
            return newProps;
        default:
            return [];
    }
}