import { useMemo, memo, useCallback } from 'react';
import { Container, DeploymentItem } from './DeploymentNav.styles';

function DeploymentNav (props) {

    const handleOpenItem = useCallback((item) => {
        if (typeof props.onChange === 'function') {
            props.onChange(item);
        }
    }, [props]);

    const renderDeployments = useMemo(() => {
        if (!props.deployments || !props.deployments.length) {
            return null;
        }

        let selectedDeployment = props.selectedDeployment || null;

        return props.deployments.filter(i=>i.parent_id === selectedDeployment).map(item=>(
            <DeploymentItem className={'size'+(props.size || '5')} key={item.id} onClick={()=>handleOpenItem(item)}>{item.nickname || item.alias || ''}</DeploymentItem>
        ));
    }, [props, handleOpenItem]);

    return <Container id="deploys_item" style={props.style || {}}>
        {renderDeployments}
    </Container>
}

export default memo(DeploymentNav);
