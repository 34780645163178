import { useEffect, useState, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getWorkOrderEntries,           
            updateWorkOrderDataID2 } from '../../../services/workorder';

import { HistoryItems, ModelContent, ButronBar, Button, Label, ErrorMsg} from '../apontamentos.styles';

import Title from '../../../../../components/Title/Title';
import Filter from '../../../../../components/Filter/Filter';
import FormRender from '../../../../../components/FormRender/FormRender';


const avaliacoes = [
    {value:'aprovado', label:'Aprovar'},
    {value:'apontamento', label:'Refazer'},
    {value:'escalado', label:'Escalar'}
];

function Avaliar(props) {
    const [items, setItems] = useState([]);
    const [avaliacao, setAvaliacao] = useState(null);
    const [error, setError] = useState('');

    const modalState = useSelector(state => state.modalState.state)

    const renderStatus = useCallback((val) => {
        if (!Boolean(props.statusAvailable && props.statusAvailable[val])) {
            return null;
        }

        return props.statusAvailable[val].name || '';
    }, [props.statusAvailable]);

    const handleSave = async () => {
        if (!avaliacao) {
            return;
        }

        dispatch(modalActions.setState('sent'));

        // let success = await updateWorkOrderData(props.id, {
        //     status: avaliacao
        // });

        let success = await updateWorkOrderDataID2(props.id, {
            status: avaliacao
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
            } else {
                setError('');
                dispatch(modalActions.setState('close'));
                if (typeof props.onRefresh === 'function') {
                    props.onRefresh();
                }
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }

        setAvaliacao(null);
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        (async () => {
            let historyData = await getWorkOrderEntries(props.id);
            console.log(historyData);
            let items = [];
            if (historyData.payload.objects) {
                items = historyData.payload.objects.map(i=>i.workorder);
            }

            if (modalState === 'loading') {
                setItems(items, props.id);
                dispatch(modalActions.setState('opened'));
            }
        })();
    }, [dispatch, props.id, modalState]);

    return <>
        <Title className={'modal'}>Avaliar</Title>
        <ModelContent>
            <Filter
                defaultValue={avaliacao}
                hideLabel={true}
                items={avaliacoes}
                onChange={(val)=>{setAvaliacao(val.value)}}
            />
            <HistoryItems>
                {(items && items.length) ? items.map((item, index) => <Fragment key={index}>
                    <Label className="historyItem">Status: {renderStatus(item.wo_pipeline_status_id)}</Label>,
                    <FormRender readOnly={true} formData={item.entry} />
                </Fragment>) : null}
            </HistoryItems>
        </ModelContent>
        {error ? <ErrorMsg>{error}</ErrorMsg> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed')); setAvaliacao(null);}}>Cancelar</Button>
            <Button onClick={()=>{handleSave()}} color="#39f" className={!avaliacao ? 'deactive' : ''}>Salvar</Button>
        </ButronBar>
    </>
}

export default Avaliar;
