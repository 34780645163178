import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getWorkorderCount = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/workorder/', {params: {
            source: 'app',
            source_id: '7',
            page:1,
            count:0,
            pipeline:'milliclick_485723149678',
            ...queryString
        }});
        finishLoading(loadingId);
        if (resp.data && resp.data.payload && resp.data.payload.count) {
            return resp.data.payload.count;
        }
        return 0;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getTaskCount = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/task/expire', {params: {
            source: 'app',
            source_id: '7',
            page:1,
            count:0,
            ...queryString
        }});
        finishLoading(loadingId);
        if (resp.data && resp.data.payload && resp.data.payload.count) {
            return resp.data.payload.count;
        }
        return 0;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getAssetsCount = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/assets', {params: {
            page:1,
            count:0,
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        if (resp.data && resp.data.payload && resp.data.payload.count) {
            return resp.data.payload.count;
        }
        return 0;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
