import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width: 200px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 101;
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 100%;
    right: 0;
    z-index: 100;
    background-color: transparent;

    &.opened {
        bottom: 0;
    }
`;

export const SelectedValue = styled.div.attrs(props=>({
    style: {
        width: props.width || '200px'
    }
}))`
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    border: 1px solid #666;
    position: relative;

    &:before {
        content: '';
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-left: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate3d(0,0,1,-45deg);
        position: absolute;
        top: 17px;
        right: 15px;
    }
`;

export const FilterBy = styled.input`
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #cacaca;
`;

export const Items = styled.div`
    width: 100%;
    max-height: 220px;
    background-color: #fff;
    overflow-y: auto;
`;

export const Item = styled.div`
    width: 100%;
    height: 40px;
    min-height: 40px;
    max-height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;

    &:hover {
        cursor: pointer;
        background-color: #000;
        color: #fff;
    }
`;