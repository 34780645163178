import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const postClient = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/data/client', {
            payload: {
                objects: {
                    client: data
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }

}