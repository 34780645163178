import styled from "styled-components";

export const Container = styled.div`

    .not-show {
        display: none;
        border: 1px solid yellow;
    }

    .image-show {
        display:flex;
        flex: 3;
        width: 1000px;
        flex-direction: column;
    }
`;

export const ParentItem = styled.div`
    border: 1px solid blue;
`;
export const CurrentItem = styled.div`
    color: #000;
    font-weight: bold;
    cursor: pointer;
`;

