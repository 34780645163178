import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getTasks = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/task', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getTaskHistory = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/task/'+id+'/history', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getTaskData = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/task/'+id, {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const updateTaskData = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/edit/task/'+id, data);
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postTaskData = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/new/task/', data);
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const deleteTask = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/milliclick/delete/task', {
            data: {
                payload: {
                    objects: {
                        id
                    }
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
