import { useState, useCallback, useEffect, useMemo } from 'react';
import { Container, Checkbox, Acoes, BtnAcao } from './relatorioservicos.styles';
import { useSelector, useDispatch } from 'react-redux';

import { modalActions, deploymentsActions } from '../../../../redux/actions';
import { getWorkOrders, getServiceReport, getStatusAvailable } from '../../services/workorder';
import { getDeployments, pathCacheResolver } from '../../services/deployments';

import Title from '../../../../components/Title/Title';
import Table from '../../../../components/Table/Table';
import Actionbar from '../../../../components/Actionbar/Actionbar';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import FilterDeployment from '../../../../components/FilterDeployment/FilterDeployment';
import Error from './modals/error';
import Ver from '../servicos/modals/ver';

function RelatorioServicos () {

    const [selectedItems, setSelectedItems] = useState([]);
    const [workOrderList, setWorkOrderList] = useState([]);
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});
    const [statusAvailable, setStatusAvailable] = useState([]);
    const [filterDeployment, setFilterDeployment] = useState(null);
    const clientId = useSelector(state=> state.permissionsState.clientId);
    const dispatch = useDispatch();

    const getListing = useCallback(async () => {
        try {
            let wo = await getWorkOrders({
                status: 'Aprovado Millicare,Reportado,Aprovado',
                order_field: orderBy.orderBy,
                order: orderBy.orderDir,
                deployment_id: filterDeployment,
            });

            if (wo && wo.payload && wo.payload.objects){
                let woList = wo.payload.objects.map(i=>i.workorder);
                setWorkOrderList(woList);
            }
        } catch (e) {
            console.error(e);
        }
    }, [orderBy, filterDeployment]);

    useEffect(() => {
        if (clientId) {
            getListing(clientId);
        }
    }, [getListing, clientId]);

    useEffect(() => {
        (async () => {
            try {
                let res = await getDeployments();
                let deployments = res.payload.objects.map(i=>i.deployment)
                if (deployments && deployments.length) {
                    dispatch(deploymentsActions.setDeployments(deployments));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            let status = await getStatusAvailable();

            let statusById = {};
            for (let s of status) {
                statusById[s.id] = s;
            }

            setStatusAvailable(statusById);
        })();
    }, []);

    const handleFilterDeployment = (deployment_id) => {
        setFilterDeployment(deployment_id);
    };

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    }

    const handleVer = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Ver id={id}/>));
    }, [dispatch]);

    const renderOptionsForStatus = useCallback((val) => {
        if (!Boolean(statusAvailable && statusAvailable[val])) {
            return null;
        }

        return statusAvailable[val].name || '';
    }, [statusAvailable]);

    const handleGerarRelatorio = async () => {
        try {
            await getServiceReport(selectedItems);
        } catch (e) {
            console.error(e);
            
            dispatch(modalActions.setState('opened'));
            dispatch(modalActions.setSize('compact'));
            dispatch(modalActions.setContent(<Error error={e.message} />));
        }
    };

    useEffect(()=>{console.log(selectedItems)}, [selectedItems]);

    const getCheked = useCallback((item) => selectedItems.some(i => i===item), [selectedItems]);
    const setChecked = useCallback((item) => {
        setSelectedItems(state => state.indexOf(item)>=0 ? state.filter(i=>i!==item) : [...state, item]);
    }, []);

    const columns = useMemo(() => ([
        {key:'id', label:'#', width: 40, adapter: (val) => (<Checkbox checked={getCheked(val)} onChange={()=>{setChecked(val)}} />)},
        {key:'status', label:'Status', adapter: (val, row) => (renderOptionsForStatus(val))},
        {key:'deployment', label:'Localização', adapter: (val, row) => pathCacheResolver(row)},
        {key:'createdBy', label:'Criado por'},
        {key:'createdAt', label:'Horário'},
        {key:'actions', label:'Ações', width: 80, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleVer(row.id)} icon={"\\F0349"} title="Ver"/>
        </Acoes>)}
    ]),[handleVer, getCheked, setChecked, renderOptionsForStatus]);

    return <Container>
        <Title>Gerar Relatório de Serviços</Title>
        <FilterDeployment value={filterDeployment} onChange={(v)=>{handleFilterDeployment(v)}} />
        <Actionbar>
            <ActionButton onClick={handleGerarRelatorio}>Gerar Relatório</ActionButton>
        </Actionbar>
        <Table
            onOrderBy={handleOrderBy}
            columns={columns}
            data={workOrderList}
        />
    </Container>
}

export default RelatorioServicos;
