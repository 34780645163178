import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';

import { ButronBar, Button, ModelContent, Pre } from '../apontamentos.styles';
import Title from '../../../../../components/Title/Title';

function GerarLink(props) {
    const modalState = useSelector(state => state.modalState.state)
    const dispatch = useDispatch();
    // http://milliclick.ami-hub.local/milliclick/workorder/edit/1/service/1
    const link = document.location.protocol + '//' + document.location.host + '/milliclick/workorder/edit/' + (props.clientId || '0') + '/appointment/' + (props.deployment || '0') + '?id='+(props.id || '0');

    useEffect(()=>{console.log(props)},[props]);

    useEffect(() => {
        if (modalState === 'loading') {
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props.id, modalState]);

    const handleCopy = useCallback(async () => {
        dispatch(modalActions.setState('close'));

        if (navigator.clipboard) {
            navigator.clipboard.writeText(link).then(function() {
              console.log('Copiado para o clipboard');
            }, function(err) {
              console.error('Erro ao copiar', err);
            });
          }

        if (typeof props.onRefresh === 'function') {
            props.onRefresh();
        }
    }, [dispatch, props, link]);

    return <>
        <Title className={'modal'}>Gerar Link de Serviço</Title>
        <ModelContent>
            <div>
                O link de serviço é:
            </div>
            <Pre>{link}</Pre>
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleCopy()} color="#09f">Copiar</Button>
        </ButronBar>
    </>
}

export default GerarLink;
