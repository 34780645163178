import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { utcToZonedTime, format } from 'date-fns-tz';
import { add } from 'date-fns';

import { Container, Acoes, BtnAcao } from './ativos.styles';

import Title from '../../../../components/Title/Title';

import Table from '../../../../components/Table/Table';
import Actionbar from '../../../../components/Actionbar/Actionbar';
import ActionButton from '../../../../components/ActionButton/ActionButton';
import FilterDeployment from '../../../../components/FilterDeployment/FilterDeployment';

import Historico from './modals/historico';
import Editar from './modals/editar';
import Remover from './modals/remover';
import Ver from './modals/ver';
import Adicionar from './modals/adicionar';

import { modalActions, deploymentsActions } from '../../../../redux/actions';
import { getTasks } from '../../services/tasks';
import { getDeployments, pathCacheResolver } from '../../services/deployments';

function Ativos () {

    const [taskList, setTaskList] = useState([]);
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});
    const [filterDeployment, setFilterDeployment] = useState(null);

    const clientId = useSelector(state=> state.permissionsState.clientId);
    const assetTypes = useSelector(state=> state.configState.app_asset_types);
    const assetForms = useSelector(state=> state.configState.app_asset_forms);
    const permissionsState = useSelector(state=> state.permissionsState.permissions);

    const dispatch = useDispatch();

    const getListing = useCallback(async () => {
        try {
            let tasks = await getTasks({type:'asset', order_field:orderBy.orderBy, order:orderBy.orderDir, deployment_id: filterDeployment});

            if (tasks && tasks.payload && tasks.payload.objects){
                let tasksList = tasks.payload.objects.map(i=>i.task);
                setTaskList(tasksList);
            }
        } catch (e) {
            console.error(e);
        }
    }, [orderBy, filterDeployment]);

    const handleFilterDeployment = (deployment_id) => {
        setFilterDeployment(deployment_id);
    };

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    }

    const handleAdicionar = useCallback((id) => {
        dispatch(modalActions.setState('opened'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Adicionar form={assetForms} types={assetTypes} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, assetForms, assetTypes]);

    const handleHistorico = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Historico id={id}/>));
    }, [dispatch]);

    const handleEditar = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Editar form={assetForms} types={assetTypes} id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing, assetForms, assetTypes]);

    const handleRemover = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('compact'));
        dispatch(modalActions.setContent(<Remover id={id} onRefresh={()=>{getListing()}}/>));
    }, [dispatch, getListing]);

    const handleVer = useCallback((id) => {
        dispatch(modalActions.setState('loading'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<Ver form={assetForms} id={id} />));
    }, [dispatch, assetForms]);

    const columns = useMemo(() => ([
        {key:'id', label:'ID', adapter: (val, row) => row.asset && row.asset.alias ? (row.asset.alias || '') : ''},
        {key:'type', label:'Tipo', adapter: (val, row) => {
            let prop = row.asset ? (row.asset.property || []) : [];
            let type = prop.filter(k => k.key === 'tipo')[0] || {};
            return assetTypes && type.value ? (assetTypes[type.value] || '') : ''
        }},
        {key:'path_cache', label:'Localização', adapter: (val, row) => pathCacheResolver(row.asset || {})},
        {key:'next_activity', label:'Próxima Execução', adapter: (val, row) => {
            let lastActivity = row.asset ? (row.asset.last_activity || '') : '';
            if (!lastActivity) {
                return '';
            }
            let dt = utcToZonedTime(lastActivity, 'America/Sao_Paulo');
            let next = add(dt, {
                years: row.schedule_type === 'year' ? row.schedule_value : 0,
                months: row.schedule_type === 'month' ? row.schedule_value : 0,
                weeks: row.schedule_type === 'week' ? row.schedule_value : 0,
                days: row.schedule_type === 'day' ? row.schedule_value : 0
            });
            return format(next, 'yyyy-MM-dd HH:mm:ss');
        }},
        {key:'last_activity', label:'Última Execução', adapter: (val, row) => {
            let lastActivity = row.asset ? (row.asset.last_activity || '') : '';
            if (!lastActivity) {
                return '';
            }
            let dt = utcToZonedTime(lastActivity, 'America/Sao_Paulo');
            return format(dt, 'yyyy-MM-dd HH:mm:ss');
        }},
        {key:'actions', label:'Ações', width: permissionsState.manager === true ? 160 : 80, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleHistorico(row.asset.id)} icon={"\\F05DA"} title="Histórico"/>
            <BtnAcao onClick={() => handleVer(row.asset.id)} icon={"\\F0349"} title="Ver"/>
            {permissionsState.manager === true ? <>
                <BtnAcao onClick={() => handleEditar(row.asset.id)} icon={"\\F03EB"} title="Editar"/>
                <BtnAcao onClick={() => handleRemover(row.asset.id)} icon={"\\F01B4"} title="Excluir"/>
            </> : null}
        </Acoes>)}
    ]),[assetTypes, handleEditar, handleHistorico, handleRemover, handleVer, permissionsState.manager]);

    useEffect(() => {
        if (clientId) {
            getListing(clientId);
        }
    }, [getListing, clientId]);

    useEffect(() => {
        (async () => {
            try {
                let res = await getDeployments();
                let deployments = res.payload.objects.map(i=>i.deployment)
                if (deployments && deployments.length) {
                    dispatch(deploymentsActions.setDeployments(deployments));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [dispatch]);

    // console.log('aqui',taskList);

    return <Container>
            <Title>Ativos</Title>
            <FilterDeployment value={filterDeployment} onChange={(v)=>{handleFilterDeployment(v)}} />
            {permissionsState.manager === true ? <Actionbar>
                <ActionButton onClick={handleAdicionar}>Adicionar Ativo</ActionButton>
            </Actionbar> : null}
            <Table
                onOrderBy={handleOrderBy}
                columns={columns}
                data={taskList}
            />
    </Container>
}

export default Ativos;
