import styled from 'styled-components';

export const PageSubtitle = styled.div`
    width: 100%;
    color: #000;
    padding: 0 0 10px 0;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 20px 0;
`;