import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getWorkOrderData, updateWorkOrderDataID4 } from '../../../services/workorder';

import { Row, Label, Input, ButronBar, Button, ModelContent, Error } from '../apontamentos.styles';
import Title from '../../../../../components/Title/Title';
import FormRender from '../../../../../components/FormRender/FormRender';

function Editar(props) {
    const [item, setitem] = useState('');
    const [error, setError] = useState('');
    const [formTitle, setFormTitle] = useState('');
    const [formDueDate, setFormDueDate] = useState('');
    const [formEntry, setFormEntry] = useState('');

    const modalState = useSelector(state => state.modalState.state)
    const frObj = useRef(null);
    const frElem = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (item) {
            setError('');
            setFormTitle(item.title || '');
            setFormDueDate(item.due_date || '');
            setFormEntry(item.entry || '');
            dispatch(modalActions.setState('opened'));
        }
    }, [item, dispatch]);

    useEffect(()=>{
        (async () => {
            if (modalState === 'loading') {
                let editData = await getWorkOrderData(props.id);
                if (editData.payload.objects) {
                    setitem((editData.payload.objects[0] || {}).workorder || null);
                }

            }
        })();
    }, [dispatch, props.id, modalState]);

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        let entry = frElem.current ? frElem.current.formRender("userData") : null;
        setFormEntry(entry);
        // let success = await updateWorkOrderData(props.id, {
        //     title: formTitle,
        //     dueDate: formDueDate,
        //     entry
        // });

        let success = await updateWorkOrderDataID4(props.id, {
            title: formTitle,
            dueDate: formDueDate,
            entry
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
            } else {
                setError('');
                dispatch(modalActions.setState('close'));
                if (typeof props.onRefresh === 'function') {
                    props.onRefresh();
                }
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, formTitle, formDueDate]);

    return <>
        <Title className={'modal'}>Editar Apontamento</Title>
        <ModelContent>
        {(item) ? <>
            <Row>
                <Label>Título</Label>
                <Input onChange={(e)=>setFormTitle(e.target.value)} value={formTitle} />
            </Row>
            <Row>
                <Label>Data</Label>
                <Input onChange={(e)=>setFormDueDate(e.target.value)} value={formDueDate}/>
            </Row>
            <Row>
                <FormRender readOnly={false} formData={formEntry} refObj={(ref, elem)=>{frObj.current = ref; frElem.current = elem}} />
                {/*<Input onChange={(e)=>setFormEntry(e.target.value)} value={formEntry}/>*/}
            </Row>
        </> : null}
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Editar;
