import { ModalTypes } from '../constants/modalConstants';

const setState = (state) => ({
    type: ModalTypes.SET_MODAL_STATE,
    state, 
});

const setContent = (content) => ({
    type: ModalTypes.SET_MODAL_CONTENT,
    content, 
});

const setSize = (size) => ({
    type: ModalTypes.SET_MODAL_SIZE,
    size, 
});

const setLoading = (key) => ({
    type: ModalTypes.SET_LOADING,
    loading: true, 
    key,
});

const setLoaded = (key) => ({
    type: ModalTypes.SET_LOADING,
    loading: false,
    key,
});

const modalActions = {
    setState,
    setContent,
    setSize,
    setLoading,
    setLoaded
};

export default modalActions;