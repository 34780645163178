import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
`;

export const Submit = styled.div`
    padding: 10px 20px;
    margin: 10px auto;
    background-color: #dadada;
    color: #fff;
    text-align: center;
    box-shadow: 0 3px 3px transparent;
    transition: all .4s ease 0s;

    &.active {
        cursor: pointer;
        background-color: #333;
    }

    &.active:hover {
        box-shadow: 0 3px 3px rgba(0,0,0, 0.4);
    }
`;

export const Success = styled.div`
    margin: 20px 20px 0 20px;
    border: 1px solid #090;
    background-color: #ada;
    padding: 20px;
    color: #000;
    cursor: pointer;
`;

export const ErrorMsg = styled.div`
    margin: 20px;
    border: 1px solid #f00;
    background-color: #fee;
    padding: 20px;
    color: #000;
    cursor: pointer;
`;

export const Collapsable = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    border: 1px solid #333;
    width: 100%;

    &:not(.active) {
        height: 30px;
        min-height: 30px;
        max-height: 30px;
        overflow: hidden;
    }
`;

export const CollapseTitle = styled.div`
    width: 100%;
    height: 30px;
    min-height: 30px;
    max-height: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    background-color: #333;
    color: #fff;
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        width: 0px;
        height: 0px;
        border: 5px solid transparent;
        border-left: 5px solid #fff;
        position: absolute;
        left: 10px;
        z-index: 1;
        transition: all .3s ease 0s;
    }
    .active &:before{
        transform: translate(-3px, 1px) rotate3d(0,0,1, 90deg);
    }

    & div {
        position: absolute;
        top: 0;
        left: 25px;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
    }
`;

export const Deployments = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
`;

export const DeploymentDetail = styled.div`
    flex: 2;
    width: 40%;
    height: 100%;
    border-left: 1px solid #333;
`;

export const DeploymentName = styled.div`
    padding: 0 20px;
`;
export const DeploymentInfo = styled.div`
    padding: 10px 20px 0;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
`;

export const Label = styled.div`
    flex: 1;
    width: 50%;
`;

export const Value = styled.div`
    flex: 1;
    width: 50%;
    font-weight: bold;
`;

export const DeploymentsQrCodes = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
`;

export const QRCode = styled.div`
    flex: 1;
    width: 50%;

    & img {
        width: 90%;
        margin: 0 auto 20px;
    }
`;

export const LabelQrCode = styled.div`
    width: 100%;
    text-align: center;
    font-size: 12px;
`;
