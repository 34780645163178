import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Input, Row, Label, Value, ButronBar, Button } from './profile.styles';

import Title from '../../../../components/Title/Title';
import Actionbar from '../../../../components/Actionbar/Actionbar';
import ActionButton from '../../../../components/ActionButton/ActionButton';

import MudarSenha from './modals/mudarSenha';
import { modalActions } from '../../../../redux/actions';
import { getMe } from '../../services/permission';
import { getUserData, updateUser } from '../../services/users';

export const funcoes = {
    manager: "Gestor",
    partner: "Parceiro Comercial",
    facility: "Gestor de Facilities",
    cleaner: "Técnico de Limpeza",
}

function Perfil () {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState('');

    const clients = useSelector(state => state.permissionsState.clients);
    const dispatch = useDispatch();

    useEffect(()=>{
        if (user && user.id) {
            (async () => {
                let data = await getUserData(user.id);

                let item = {};
                if (data.payload.objects) {
                    item = (data.payload.objects[0] || {}).user || null;
                }

                setUserData(item);
            })();
        }
    }, [user]);

    useEffect(()=>{
        if (userData) {
            setUserName(userData.nome || '');
            setUserPhone(userData.celular || '');
        }
    }, [userData]);

    const empresa = useMemo(() => {
        if (!userData || !clients) {
            return '';
        }

        let client = clients.filter(client => {
            return parseInt(client.clients_id) === userData.empresaResponsavel
        })[0] || {};

        return client.clients_name || '';
    },[clients, userData]);

    const listaEmpresas = useMemo(() => {
        return clients.filter(client => {
            if (!userData || !userData.empresaUsuario || !userData.empresaUsuario.length) {
                return [];
            }
            return userData.empresaUsuario.map(a=>parseInt(a)).indexOf(parseInt(client.clients_id)) >= 0
        }).map(client => client.clients_name);
    },[clients, userData]);

    const hasChanges = useMemo(() => {
        return (
            (userName.length > 0 && userName !== userData.nome) ||
            (userPhone.length > 0 && userPhone !== userData.celular)
        )
    }, [userData, userName, userPhone]);

    const getData = useCallback(async () => {
        try {
            let res = await getMe();

            if (res && res.payload && res.payload.objects && res.payload.objects[0]){
                let user = res.payload.objects[0].user || {};
                setUser(user);
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const handleMudarSenha = useCallback(() => {
        dispatch(modalActions.setState('opened'));
        dispatch(modalActions.setSize('normal'));
        dispatch(modalActions.setContent(<MudarSenha id={user.id}/>));
    }, [dispatch, user]);

    const handleSave = useCallback(async (id) => {
        if (user && hasChanges) {
            await updateUser(user.id, {
                nome: userName,
                celular: userPhone
            });
            setUserData(s => ({...s, nome: userName}));
        }
    }, [user, userName, userPhone, hasChanges]);

    useEffect(() => {
        getData();
    }, [getData]);

    return <Container>
            <Title>Meu Perfil</Title>
            <Actionbar>
                <ActionButton onClick={handleMudarSenha}>Mudar Senha</ActionButton>
            </Actionbar>
            {userData ? <>
            <Row></Row>
            <Row>
                <Label>Nome</Label>
                <Input value={userName} onChange={e => setUserName(e.target.value)}/>
            </Row>
            <Row>
                <Label>Celular</Label>
                <Input value={userPhone} onChange={e => setUserPhone(e.target.value)}/>
            </Row>
            <Row>
                <Label>E-mail</Label>
                <Value>{user ? userData.email || '' : ''}</Value>
            </Row>
            <Row>
                <Label>Empresa</Label>
                <Value>{empresa}</Value>
            </Row>
            <Row>
                <Label>Função</Label>
                <Value>{funcoes[userData.funcao] || '-'}</Value>
            </Row>
            <Row>
                <Label>Empresas com acesso</Label>
                <Value>{listaEmpresas ? listaEmpresas.map((emp,i) => (<div key={i}>{emp}</div>)) : null}</Value>
            </Row>
            <ButronBar>
                <Button className={hasChanges ? '' : 'deactive'} onClick={()=>{handleSave()}}>Salvar</Button>
            </ButronBar>
            </> : null}
    </Container>
}

export default Perfil;