import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    width: 100%;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
`;

export const Icon = styled.div.attrs(props=>({
    style: {
        content: props.icon
    }
}))`
    width: 40px;
    height: 40px;
    color: inheret;
    font-family: 'Material Design Icons';
    font-size: 16px;
`;

export const Label = styled.div`
    flex: 1;
    color: inheret;
`;

export const Separator = styled.div`
    height: 1px;
    min-height: 1px;
    background-color: #555;
    margin: 10px 0;
    width: 100%;
`;