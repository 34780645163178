import { DeploymentsTypes } from '../constants/deploymentConstants';

const initialState = {
    deployments: null,
    current_deployment: null
};

export const deploymentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DeploymentsTypes.SET_DEPLOYMENTS:
            return {
                ...state,
                deployments: [...action.deployments]
            };

        case DeploymentsTypes.SET_CURRENT_DEPLOYMENT:
            return {
                ...state,
                current_deployment: action.deployment_id
            };

        case 'RESET':
            return {...initialState};
        
        default:
            return state;
    }
};