import { useState, memo, useMemo, useCallback, useEffect } from 'react';
import { Container, Row, Label, Input } from './Form.styles';

function FormCNPJ (props) {
    const [dataRazaoSocial, setDataRazaoSocial] = useState('');
    const [dataCNPJ, setDataCNPJ] = useState('');
    const [dataNomeFantasia, setDataNomeFantasia] = useState('');
    const [dataEndereco, setDataEndereco] = useState('');
    const [dataNomeRepresentante, setDataNomeRepresentante] = useState('');
    const [dataEmailRepresentante, setDataEmailRepresentante] = useState('');

    const isValidCNPJ = useMemo(() => {
        if (!dataCNPJ) {
            return false;
        }

        if (dataCNPJ.length !== 14)
        return false;

        if (dataCNPJ === "00000000000000" ||
            dataCNPJ === "11111111111111" ||
            dataCNPJ === "22222222222222" ||
            dataCNPJ === "33333333333333" ||
            dataCNPJ === "44444444444444" ||
            dataCNPJ === "55555555555555" ||
            dataCNPJ === "66666666666666" ||
            dataCNPJ === "77777777777777" ||
            dataCNPJ === "88888888888888" ||
            dataCNPJ === "99999999999999") {
            return false;
        }

        let tamanho = dataCNPJ.length - 2;
        let numeros = dataCNPJ.substring(0,tamanho);
        let digitos = dataCNPJ.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (String(resultado) !== String(digitos.charAt(0)))
            return false;

        tamanho = tamanho + 1;
        numeros = dataCNPJ.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (String(resultado) !== String(digitos.charAt(1)))
            return false;

        return true;
    }, [dataCNPJ]);

    const isValidEmail = useMemo(() => {
        if (dataEmailRepresentante) {
            return true;
        } else {
            return false;
        }
    }, [dataEmailRepresentante]);

    const formatCNPJ = useMemo(() => {
        if (dataCNPJ.length <= 2) {
            return dataCNPJ;
        } else if (dataCNPJ.length <= 5) {
            return dataCNPJ.substr(0,2) + '.' + dataCNPJ.substr(2,3);
        } else if (dataCNPJ.length <= 8) {
            return dataCNPJ.substr(0,2) + '.' + dataCNPJ.substr(2,3) + '.' + dataCNPJ.substr(5,3);
        } else if (dataCNPJ.length <= 12) {
            return dataCNPJ.substr(0,2) + '.' + dataCNPJ.substr(2,3) + '.' + dataCNPJ.substr(5,3) + '/' + dataCNPJ.substr(8,4);
        } else {
            return dataCNPJ.substr(0,2) + '.' + dataCNPJ.substr(2,3) + '.' + dataCNPJ.substr(5,3) + '/' + dataCNPJ.substr(8,4) + '-' + dataCNPJ.substr(12,2);
        }
    }, [dataCNPJ]);

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                razaoSocial: dataRazaoSocial,
                cnpj: dataCNPJ,
                nomeFantasia: dataNomeFantasia,
                endereco: dataEndereco,
                nomeRepresentante: dataNomeRepresentante,
                emailRepresentante: dataEmailRepresentante
            }, isValidCNPJ && dataRazaoSocial && dataNomeFantasia && dataEndereco && dataNomeRepresentante && dataEmailRepresentante);
        }

    }, [props, isValidEmail, isValidCNPJ, dataRazaoSocial, dataCNPJ, dataNomeFantasia, dataEndereco, dataNomeRepresentante, dataEmailRepresentante])

    const handleCNPJ = useCallback((val) => {
        setDataCNPJ(val.replace(/[^\d]/gi, ''));
    }, []);



    return <Container>
        <Row>
            <Label>Razão Social</Label>
            <Input value={dataRazaoSocial} onChange={e=>setDataRazaoSocial(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>CNPJ</Label>
            <Input value={formatCNPJ} onChange={e=>handleCNPJ(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Nome Fantasia</Label>
            <Input value={dataNomeFantasia} onChange={e=>setDataNomeFantasia(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Endereço</Label>
            <Input value={dataEndereco} onChange={e=>setDataEndereco(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Nome do Representante na Empresa</Label>
            <Input value={dataNomeRepresentante} onChange={e=>setDataNomeRepresentante(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Email do Representante na Empresa</Label>
            <Input value={dataEmailRepresentante} onChange={e=>setDataEmailRepresentante(e.target.value)} type="text" />
        </Row>
    </Container>
}

export default memo(FormCNPJ);
