import { useEffect, useRef } from "react";
import { Wrapper, Form, Fieldset } from './FormRender.styles';
import './magnific-popup.css';

function FormRender ({readOnly, formData, refObj}) {

    const frRef = useRef(null);
    const frObj = useRef(null);
    const frElem = useRef(null);

    useEffect(() => {
        if (frRef.current) {
            window.$(frRef.current).append('<div class="fr-editor-div">');
            frObj.current = window.$('.fr-editor-div', frRef.current).formRender({formData: formData});
            frElem.current = window.$('.fr-editor-div', frRef.current);
            if (frObj.current && typeof refObj === 'function') {
                refObj(frObj.current, frElem.current);
            }
        }

        let ref = frRef.current;

        return () => {
            window.$(ref).empty();
        };

    }, [formData, refObj]);

    return <Wrapper>
        <Fieldset disabled={readOnly} ref={frRef}>
            <Form className="fr-editor" />
        </Fieldset>
    </Wrapper>
}

export default FormRender;
