import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { postAssetData } from '../../../services/assets';
import { getDeployments } from '../../../services/deployments';

import { Row, Label, Select, ButronBar, Button, ModelContent, Error } from '../ativos.styles';
import Title from '../../../../../components/Title/Title';
import FormBuilderOE from '../../../../../components/FormBuilderOE/FormBuilderOE';
import DatePicker from "react-datepicker";

function Adicionar(props) {
    const [error, setError] = useState('');
    const [deployments, setDeployments] = useState([]);
    const [formImovel, setFormImovel] = useState('');
    const [formTipo, setFormTipo] = useState('');
    const [formProximaLimpeza, setFormProximaLimpeza] = useState('');
    const [formData, setFormData] = useState([]);

    const dispatch = useDispatch();
    const assetConfig = useSelector(state => state.configState.app_asset_config);

    const cleanForm = () => {
        setFormImovel('');
        setFormTipo('');
        setFormData([]);
        setFormProximaLimpeza('');
    };

    const formFrequenciaLimpeza = useMemo(()=>{
        return assetConfig && formTipo && assetConfig[formTipo] ? (assetConfig[formTipo]['frequencia_limpeza'] ?? '') : '';
    },[formTipo, assetConfig]);

    const propsWithType = useMemo(()=> {
        let propVal = Object.keys(formData).filter(key=>key!=='tipo').map(key => ({key, value: formData[key]}));
        let result = [...propVal, {key: 'tipo', value: formTipo}]
        return result;
    }, [formTipo, formData]);

    const currentForm = useMemo(()=>{
        return props.form ? (props.form[formTipo] || []) : []
    },[props.form, formTipo]);

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        let success = await postAssetData({
            deployment_id: formImovel,
            property: propsWithType,
            proxima_limpeza: formProximaLimpeza,
            frequencia_limpeza: formFrequenciaLimpeza
        });

        if (success) {
            //let asset_id = null;
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
                return;
            }

            /*if (success.payload && success.payload.objects && success.payload.objects[0] && success.payload.objects[0].assets) {
                asset_id = success.payload.objects[0].assets.id

                await postTaskData({
                    asset_id,
                    schedule_value: formLimpezaValor,
                    schedule_type: formLimpezaTipo
                });
            }*/

            setError('');
            dispatch(modalActions.setState('close'));
            cleanForm();
            if (typeof props.onRefresh === 'function') {
                props.onRefresh();
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, formImovel, propsWithType, formProximaLimpeza, formFrequenciaLimpeza]);

    useEffect(()=>{
        (async () => {
            let deployment = await getDeployments();
            if (deployment && deployment.payload && deployment.payload.objects) {
                setDeployments(deployment.payload.objects.map(i=>i.deployment) || []);
            }
        })();
    }, []);

    return <>
        <Title className={'modal'}>Adicionar Ativo</Title>
        <ModelContent>
        <Row>
            <Label>Operação</Label>
            <Select onChange={(e)=>setFormImovel(e.target.value)} value={formImovel}>
                <option value=""></option>
                {deployments && deployments.length ? deployments.map(deployment => (
                    <option key={deployment.id} value={deployment.id}>{deployment.nickname || deployment.alias || ''}</option>
                )) : null }
            </Select>
        </Row>
        <Row>
            <Label>Tipo</Label>
            <Select onChange={(e)=>setFormTipo(e.target.value)} value={formTipo}>
                <option value=""></option>
                {props.types ? Object.keys(props.types).map(tipo => (
                    <option key={tipo} value={tipo}>{props.types[tipo] || ''}</option>
                )) : null}
            </Select>
        </Row>
        <Row>
            <Label>Proxima atividade de limpeza</Label>
            <Label>
                <DatePicker className="input-calendar" onChange={(e)=>setFormProximaLimpeza(e)} selected={formProximaLimpeza} dateFormat={'dd/MM/yyyy'}/>
            </Label>
        </Row>
        <FormBuilderOE form={currentForm} onChange={(value)=>{setFormData(value)}}/>
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>handleSalvar()} color="#39f">Salvar</Button>
        </ButronBar>
    </>
}

export default Adicionar;