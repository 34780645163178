import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';

import "./styles/@mdi/font/css/materialdesignicons.min.css";
import "react-datepicker/dist/react-datepicker.css";
import './styles/index.css';

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
