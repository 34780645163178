import { Container, CurrentItem, ParentItem } from './Breadcumb.styles';

function Breadcumb (props) {

    const handleClick = (value) => {
        if (props.onSelect && typeof props.onSelect === 'function') {
            props.onSelect(value);
        }
    }

    return <Container>
        {props.path && props.path.length ? <ParentItem onClick={() => {handleClick(null)}}>Início</ParentItem> : <CurrentItem>Início</CurrentItem>}
        {Boolean(props.path && props.path.length) ? (props.path.map((item, index) => {
            if (index === props.path.length - 1) {
                return <CurrentItem key={index} onClick={() => {handleClick(item.id)}}>{item ? item.value : ''}</CurrentItem>
            } else {
                return <ParentItem key={index} onClick={() => {handleClick(item.id)}}>{item ? item.value : ''}</ParentItem>
            }
        })) : null}
    </Container> 
}

export default Breadcumb;