import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { postUser } from '../../../services/users';

import { Row, Label, Input, Select, ButronBar, Button, ModelContent, Error } from '../users.styles';
import { Radio, RadioText, RadioItem, RadioList} from '../../forms/Form.styles';
import Title from '../../../../../components/Title/Title';
import Multiselect from '../../../../../components/Multiselect/Multiselect';


function Adicionar(props) {
    const [error, setError] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [dataCPF, setDataCPF] = useState('');
    const [dataNome, setDataNome] = useState('');
    const [dataEmail, setDataEmail] = useState('');
    const [dataCelular, setDataCelular] = useState('');
    const [dataEmpresaResponsavel, setDataEmpresaResponsavel] = useState('');
    const [dataEmpresaUsuario, setDataEmpresaUsuario] = useState([]);
    const [dataFuncao, setDataFuncao] = useState('');

    const clients = useSelector(state=> state.permissionsState.clients);
    const permissionsState = useSelector(state=> state.permissionsState.permissions);
    const dispatch = useDispatch();

    const cleanForm = () => {
        setDataCPF('');
        setDataNome('');
        setDataEmail('');
        setDataCelular('');
        setDataEmpresaResponsavel('');
        setDataEmpresaUsuario([]);
        setDataFuncao('');
    };

    const isValidCPF = useMemo(() => {
        if (!dataCPF) {
            return false;
        }

        // https://gist.github.com/joaohcrangel/8bd48bcc40b9db63bef7201143303937
        let sum;
        let rest;
        sum = 0;
        if (dataCPF === "00000000000") return false;
    
        for (let i=1; i<=9; i++) sum = sum + parseInt(dataCPF.substring(i-1, i)) * (11 - i);
        rest = (sum * 10) % 11;
    
        if ((rest === 10) || (rest === 11))  rest = 0;
        if (rest !== parseInt(dataCPF.substring(9, 10)) ) return false;
    
        sum = 0;
        for (let i = 1; i <= 10; i++) sum = sum + parseInt(dataCPF.substring(i-1, i)) * (12 - i);
        rest = (sum * 10) % 11;
    
        if ((rest === 10) || (rest === 11))  rest = 0;
        if (rest !== parseInt(dataCPF.substring(10, 11) ) ) return false;
        return true;
    }, [dataCPF]);

    const formatCPF = useMemo(() => {
        if (dataCPF.length <= 3) {
            return dataCPF;
        } else if (dataCPF.length <= 6) {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3);
        } else if (dataCPF.length <= 9) {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3) + '.' + dataCPF.substr(6,3);
        } else {
            return dataCPF.substr(0,3) + '.' + dataCPF.substr(3,3) + '.' + dataCPF.substr(6,3) + '-' + dataCPF.substr(9,2);
        }
    }, [dataCPF]);

    const formatCelular = useMemo(() => {
        if (dataCelular.length < 1) {
            return '';
        } else if (dataCelular.length > 1 && dataCelular.length < 2) {
            return'('+dataCelular;
        } else if (dataCelular.length <= 7) {
            return'('+dataCelular.substr(0,2)+') '+dataCelular.substr(2);
        } else {
            return'('+dataCelular.substr(0,2)+') '+dataCelular.substr(2,5) + '-' + dataCelular.substr(7,4);
        }
    }, [dataCelular]);

    const listaEmpresas = useMemo(() => {
        return clients.map(client => ({
            value: client.clients_id,
            label: client.clients_name
        }));
    },[clients]);

    useEffect(() => {
        setIsValid(dataCPF && dataNome && dataEmail && dataEmpresaResponsavel && dataEmpresaUsuario.length && dataFuncao && isValidCPF);
    }, [dataCPF, dataNome, dataEmail, dataCelular, dataEmpresaResponsavel, dataEmpresaUsuario, dataFuncao, isValidCPF])

    const handleSalvar = useCallback(async () => {
        dispatch(modalActions.setState('sent'));

        let success = await postUser({
            cpf: dataCPF,
            nome: dataNome,
            email: dataEmail,
            celular: dataCelular,
            empresaResponsavel: dataEmpresaResponsavel,
            empresaUsuario: dataEmpresaUsuario,
            funcao: dataFuncao,
        });

        if (success) {
            if (success.toast && success.toast.type !== 'success') {
                setError(success.toast.message);
                dispatch(modalActions.setState('opened'));
                return;
            }
            
            setError('');
            dispatch(modalActions.setState('close'));
            cleanForm();
            if (typeof props.onRefresh === 'function') {
                props.onRefresh();
            }
        } else {
            setError('Ocorreu um erro ao salvar. Revise os dados e tente novamente');
            dispatch(modalActions.setState('opened'));
        }
    }, [dispatch, props, dataCPF, dataNome, dataEmail, dataCelular, dataEmpresaResponsavel, dataEmpresaUsuario, dataFuncao]);

    const handleCPF = useCallback((val) => {
        setDataCPF(val.replace(/[^\d]/gi, ''));
    }, []);

    const handleCelular = useCallback((val) => {
        setDataCelular(val.replace(/[^\d]/gi, ''));
    }, []);

    return <>
        <Title className={'modal'}>Cadastrar usuário</Title>
        <ModelContent>
            <Row>
                <Label>CPF</Label>
                <Input value={formatCPF} onChange={e=>handleCPF(e.target.value)} type="text" />
            </Row>
            <Row>
                <Label>Nome Completo</Label>
                <Input value={dataNome} onChange={e=>setDataNome(e.target.value)} type="text" />
            </Row>
            <Row>
                <Label>Email</Label>
                <Input value={dataEmail} onChange={e=>setDataEmail(e.target.value)} type="text" />
            </Row>
            <Row>
                <Label>Celular</Label>
                <Input value={formatCelular} onChange={e=>handleCelular(e.target.value)} type="text" />
            </Row>
            <Row>
                <Label>Empresa para qual usuário será cadastrado</Label>
                <Select value={dataEmpresaResponsavel} onChange={e=>setDataEmpresaResponsavel(e.target.value)}>
                    <option value=""></option>
                    {listaEmpresas.map(item => (
                        <option value={item.value}>{item.label}</option>
                    ))}
                </Select>
            </Row>
            <Row>
                <Label>Empresas que o usuário conseguirá visualizar</Label>
                <Multiselect items={listaEmpresas} value={dataEmpresaUsuario} onChange={val=>setDataEmpresaUsuario(val)} />
            </Row>
            <Row>
                <Label>Função</Label>
                <RadioList>
                    {permissionsState.manager === true ? <>
                        <RadioItem>
                            <Radio id="radio-manager" value="manager" checked={dataFuncao === 'manager'} onChange={e=>setDataFuncao(e.target.value)} />
                            <RadioText htmlFor="radio-manager">Gestor – Pode cadastrar empresas/pessoas e usuários</RadioText>
                        </RadioItem>
                        <RadioItem>
                            <Radio id="radio-partner" value="partner" checked={dataFuncao === 'partner'} onChange={e=>setDataFuncao(e.target.value)} />
                            <RadioText htmlFor="radio-partner">Parceiro Comercial – Pode cadastrar empresas/pessoas e usuários</RadioText>
                        </RadioItem>
                        <RadioItem>
                            <Radio id="radio-facility" value="facility" checked={dataFuncao === 'facility'} onChange={e=>setDataFuncao(e.target.value)} />
                            <RadioText htmlFor="radio-facility">Gestor de Facilities – Pode aprovar ou escalar apontamentos</RadioText>
                        </RadioItem>
                    </> : null}
                    <RadioItem>
                        <Radio id="radio-cleaner" value="cleaner" checked={dataFuncao === 'cleaner'} onChange={e=>setDataFuncao(e.target.value)} />
                        <RadioText htmlFor="radio-cleaner">Técnico de Limpeza – Pode Responder apontamentos</RadioText>
                    </RadioItem>
                </RadioList>
            </Row>
        </ModelContent>
        {error ? <Error>{error}</Error> : null}
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
            <Button onClick={()=>isValid ? handleSalvar() : null} color="#39f" className={!isValid ? 'deactive' : ''}>Salvar</Button>
        </ButronBar>
    </>
}

export default Adicionar;