import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { configReducer } from './configReducer';
import { deploymentsReducer } from './deploymentsReducer';
import { modalReducer } from './modalReducer';
import { permissionsReducer } from './permissionsReducer';

export const Reducers = combineReducers({
    configState: configReducer,
    deploymentsState: deploymentsReducer,
    modalState: modalReducer,
    permissionsState: permissionsReducer,
    routing: routerReducer
});