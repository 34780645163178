import { PermissionTypes } from '../constants/permissionsConstants';

const setPermissions = (permissions) => ({
    type: PermissionTypes.SET_PERMISSION,
    permissions, 
});

const setClients = (clients) => ({
    type: PermissionTypes.SET_CLIENTS,
    clients, 
});

const setClientId = (clientId) => ({
    type: PermissionTypes.SET_CLIENT_ID,
    clientId, 
});

const setMe = (me) => ({
    type: PermissionTypes.SET_ME,
    me, 
});

const permissionsActions = {
    setPermissions,
    setClients,
    setClientId,
    setMe
};

export default permissionsActions;