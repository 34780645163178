import styled from "styled-components"; 

export const Container = styled.div`
    padding: 20px;
`;

export const Tabs = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #000;
`;

export const Tab = styled.div`
    padding: 5px 20px;
    background-color: #fff;
    color: #555;
    border: 1px solid #555;
    cursor: pointer;

    &.active {
        color: #fff;
        background-color: #222;
    }
`;

export const Submit = styled.div`
    padding: 10px 20px;
    margin: 10px auto;
    background-color: #dadada;
    color: #fff;
    text-align: center;
    box-shadow: 0 3px 3px transparent;
    transition: all .4s ease 0s;

    &.active {
        cursor: pointer;
        background-color: #333;
    }

    &.active:hover {
        box-shadow: 0 3px 3px rgba(0,0,0, 0.4);
    }
`;

export const Success = styled.div`
    margin: 20px 0;
    border: 1px solid #090;
    background-color: #ada;
    padding: 20px;
    color: #000;
`;

export const ErrorMessage = styled.div`
    margin: 20px 0;
    border: 1px solid #f00;
    background-color: #fee;
    padding: 20px;
    color: #000;
`;