import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getWorkOrderEntries } from '../../../services/workorder';

import { Row, Label, Value, ModelContent, ButronBar, Button } from '../servicos.styles';
import Title from '../../../../../components/Title/Title';
import FormRender from '../../../../../components/FormRender/FormRender';

function Ver(props) {
    const [firstItem, setFirstItem] = useState(null);
    const [lastItem, setLastItem] = useState(null);
    
    const modalState = useSelector(state => state.modalState.state)

    const dispatch = useDispatch();

    useEffect(()=>{
        (async () => {
            let viewData = await getWorkOrderEntries(props.id);
            let item = [];
            if (viewData.payload.objects) {
                item = viewData.payload.objects.map(i=>i.workorder);
            }

            if (modalState === 'loading') {
                setFirstItem(item[0] || {});
		let lastIdx = item.length-1;
		for(let i =item.length-1; i >= 0; i--){
			
			if(item[i].wo_pipeline_status_id === 26 || item[i].wo_pipeline_status_id === 27 || item[i].wo_pipeline_status_id === 28){
				lastIdx = i;
				//console.log("Encontrado",item[i]);
				break;
			}
		}
                setLastItem(item[lastIdx] || {});
                dispatch(modalActions.setState('opened'));
            }
        })();
    }, [dispatch, props.id, modalState]);

    return <>
        <Title className={'modal'}>Visualizar Dados</Title>
        <ModelContent>
        {(firstItem) ? <>
            <Row>
                <Label>Título</Label>
                <Value>{firstItem.title}</Value>
            </Row>
            <Row>
                <Label>Data</Label>
                <Value>{firstItem.due_date}</Value>
            </Row>
            <Row>
                <Label>Entrada Inicial</Label>
            </Row>
            <Row>
                <FormRender readOnly={true} formData={firstItem.entry} />
            </Row>
        </> : null }
        {(lastItem) ? <>
            <Row>
                <Label>Última Entrada</Label>
            </Row>
            <Row>
                <FormRender readOnly={true} formData={lastItem.entry} />
            </Row>
        </> : null}
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
        </ButronBar>
    </>
}

export default Ver;
