import { useState, memo, useMemo, useEffect } from 'react';
import { Container, Row, Label, Input, TextArea, UploadLabel, Select, UploadItem } from './Form.styles';

function FormImovel (props) {
    const [dataNickname, setDataNickname] = useState('');
    const [dataBlueprint, setDataBlueprint] = useState('');
    const [dataScale, setDataScale] = useState('');
    const [dataParent_id, setDataParent_id] = useState('');
    const [dataDescription, setDataDescription] = useState('');
    const [dataCamadaMilliclick, setDataCamadaMilliclick] = useState('');

    useEffect(() => {
        if (props.onChange) {
            props.onChange({
                nickname: dataNickname,
                alias: 'deployment'+(new Date()).getTime(),
                blueprint: dataBlueprint,
                scale: dataScale,
                parent_id: dataParent_id || null,
                description: dataDescription || '',
                camadaMilliclick: dataCamadaMilliclick,
                origin_x: 0,
                origin_y: 0,
                zone: 'America/Sao_Paulo'
            }, dataNickname);
        }
    }, [props, dataNickname, dataBlueprint, dataScale, dataParent_id, dataDescription, dataCamadaMilliclick])

    const camadaMilliclickFilename = useMemo(() => {
        return dataCamadaMilliclick ? dataCamadaMilliclick[0].name : 'Nenhum arquivo selecionado';
    }, [dataCamadaMilliclick]);

    const blueprintFilename = useMemo(() => {
        return dataBlueprint && dataBlueprint[0]  ? dataBlueprint[0].name : 'Nenhum arquivo selecionado';
    }, [dataBlueprint]);

    return <Container>
        <Row>
            <Label>Nome</Label>
            <Input value={dataNickname} onChange={e=>setDataNickname(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Planta</Label>
            <UploadLabel htmlFor="data-blueprint">{blueprintFilename}</UploadLabel>
            <UploadItem id="data-blueprint" onChange={e=>setDataBlueprint(e.target.files)} />
        </Row>
        <Row>
            <Label>Escala (em pixels por metro)</Label>
            <Input value={dataScale} onChange={e=>setDataScale(e.target.value)} type="text" />
        </Row>
        <Row>
            <Label>Operação Pai</Label>
            <Select value={dataParent_id} onChange={e=>setDataParent_id(e.target.value)}>
                <option value=""></option>
                {Boolean(props.imoveis && props.imoveis.length) ? props.imoveis.map(item => (
                    <option value={item.id} key={item.id}>{item.nickname || item.alias || ''}</option>
                )) : null}
            </Select>
        </Row>
        <Row>
            <Label>Endereço</Label>
            <TextArea value={dataDescription} onChange={e=>setDataDescription(e.target.value)}></TextArea>
        </Row>
        <Row>
            <Label>Camada Milliclick</Label>
            <UploadLabel htmlFor="data-camada-milliclick">{camadaMilliclickFilename}</UploadLabel>
            <UploadItem id="data-camada-milliclick" onChange={e=>setDataCamadaMilliclick(e.target.files)} />
        </Row>
        <Row>
            <Label>Testes</Label>
        </Row>
    </Container>
}

export default memo(FormImovel);