import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { modalActions } from '../../../../../redux/actions';
import { getAssetData, getAssetQRCode, getTasksFromAsset } from '../../../services/assets';
import { convertToBase64 } from '../../../../../util/image';

import { propertiesValues } from '../properties';
import { Row, Label, Value, ModelContent, ButronBar, Button } from '../ativos.styles';
import Title from '../../../../../components/Title/Title';

import FormBuilderOE from '../../../../../components/FormBuilderOE/FormBuilderOE';

let getProp = (key, properties)=> {
    if (!properties || !Array.isArray(properties)) {
        return '';
    }

    for (let prop of properties) {
        if (prop.key === key) {
            return propertiesValues[key] ? (propertiesValues[key][prop.value] || prop.value) : prop.value;
        }
    }

    return JSON.stringify(propertiesValues[key]) || '';
}

let getValue = (key, properties)=> {
    if (!properties || !Array.isArray(properties)) {
        return '';
    }

    for (let prop of properties) {
        if (prop.key === key) {
            return prop.value;
        }
    }

    return JSON.stringify(propertiesValues[key]) || '';
}

function Ver(props) {
    const [item, setItem] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [qrCode, setQrCode] = useState(null);
    const [formTipo, setFormTipo] = useState('');
    const [formData, setFormData] = useState({});

    const modalState = useSelector(state => state.modalState.state)

    const dispatch = useDispatch();

    const currentForm = useMemo(()=>{
        return props.form[formTipo] || []
    },[props.form, formTipo]);

    useEffect(()=>{
        (async () => {
            if (modalState !== 'loading') {
                return;
            }

            let viewData = await getAssetData(props.id);
            let item = null;
            if (viewData.payload.objects) {
                item = (viewData.payload.objects[0] || {}).asset || null;
            }

            let tasksData = await getTasksFromAsset(props.id);
            let tasks = null;
            if (tasksData.payload && tasksData.payload.objects) {
                tasks = tasksData.payload.objects.map(i=>i.task) || [];
            }

            let qrCodeData = await getAssetQRCode(props.id);

            setItem(item);
            setTasks(tasks);
            setQrCode(convertToBase64(qrCodeData));
            dispatch(modalActions.setState('opened'));
        })();
    }, [dispatch, props.id, modalState]);

    useEffect(()=>{
        if (item) {
            setFormTipo(getValue('tipo', item.property));
            setFormData(item.property ? item.property.reduce((acc, cur) => ({...acc, [cur.key]: cur.value}), {}) : {});
        }
    }, [item]);

    return <>
        <Title className={'modal'}>Visualizar Dados</Title>
        <ModelContent>
        {(item) ? <>
            <Row>
                <Label>Operação</Label>
                <Value>{item.deployment}</Value>
            </Row>
            <Row>
                <Label>Tipo</Label>
                <Value>{getProp('tipo', item.property)}</Value>
            </Row>
            <FormBuilderOE viewMode={true} form={currentForm} data={formData} />
            <Row>
                <Label flex={3}>
                    <Row>
                        <Label>Atividades</Label>
                    </Row>
                    {tasks ? tasks.map(task => (
                        <Row key={task.id}>
                            <Label><b>{task.nickname || task.alias || ''}</b> realizada em <b>{task.date}</b></Label>
                        </Row>
                    )) : null}
                </Label>
                <Label flex={2}>
                    { qrCode ? <img src={qrCode} alt="qrcode" /> : null }
                </Label>
            </Row>
        </> : null}
        </ModelContent>
        <ButronBar>
            <Button onClick={()=>{dispatch(modalActions.setState('closed'))}}>Cancelar</Button>
        </ButronBar>
    </>
}

export default Ver;
