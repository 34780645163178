import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    border:0px solid red;
`;

export const DeploymentItem = styled.div`
    width: calc(20% - 8px);
    min-width: calc(20% - 8px);
    margin: 0 10px 10px 0;
    background-color: #efefef;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    box-sizing: border-box;
    padding: 0 20px;
    cursor: pointer;

    &.size5 {
        width: calc(20% - 8px);
        min-width: calc(20% - 8px);
        margin: 0 10px 10px 0;
    }
    &.size5:nth-child(5n) {
        margin-right: 0;
    }

    &.size4 {
        width: calc(25% - 7px);
        min-width: calc(25% - 7px);
    }
    &.size4:nth-child(4n) {
        margin-right: 0;
        width: calc(25% - 2px);
        min-width: calc(25% - 2px);
    }

    &.size3 {
        width: calc(33.3333% - 7px);
        min-width: calc(33.3333% - 7px);
    }
    &.size3:nth-child(3n) {
        margin-right: 0;
        width: calc(33.3333% - 6px);
        min-width: calc(25% - 6px);
    }

    &:hover {
        background-color: #e0e0e0;
    }
`;
