import styled from "styled-components"; 

export const Container = styled.div`
    padding: 20px;
`;

export const Acoes = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: 1;

    .tablerow:hover & {
        opacity: 1;
    }

    @media only screen and (max-width: 900px) {
        & {
            opacity: 1;
        }
    }
`;

export const BtnAcao = styled.div`
    width: 30px;
    height: 30px;
    position: relative;
    margin-right: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background-color: #333;
    }

    &:hover:before {
        color: #fff;
    }


    &:before {
        font-size: 20px;
        content: '${props => props.icon}';
        font-family: "Material Design Icons";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #333;
    }
`;

export const ModelContent = styled.div`
    flex: 1;
    padding: 20px;
    overflow: auto;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    margin-bottom: 10px;
`;

export const Input = styled.input`
    flex: 1;
    border: 1px solid #cacaca;
    height: 30px;
    padding: 0 5px;
`;

export const Select = styled.select`
    flex: 1;
    border: 1px solid #cacaca;
    height: 30px;
    padding: 0 5px;
    box-sizing: border-box;
`;

export const Label = styled.div`
    flex: 1;
    color: #333;
`;

export const Value = styled.div`
    flex: 1;
    font-weight: 900;
    color: #333;
`;

export const Error = styled.div`
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid #f00;
    background-color: #fcc;
`;

export const ButronBar = styled.div`
    height: 40px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

export const Button = styled.div`
    text-align: center;
    width: 100px;
    height: 40px;
    border: 1px solid ${props => props.color ? props.color : 'transparent'};
    color: ${props => props.color ? props.color : '#000'};
    transition: all .3s ease 0s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &:not(:last-child) {
        margin-right: 10px;
    }

    &:not(.deactive):hover {
        background-color: ${props => props.color ? props.color : '#666'};
        color: #fff;
        cursor: pointer;
    }


`;