import styled from 'styled-components';

export const PageTitle = styled.div`
    width: 100%;
    color: #000;
    padding: 0 0 20px 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 900;

    &.modal {
        padding: 20px;
        border-bottom: 1px solid #cacaca;
    }
`;