import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { FilterDeployment, LabelDeployment, SelectDeployment } from './FilterDeployment.styles';
import { resolveParentPath, resolveParentPathId } from '../../util/deployment';

import SelectFilter from '../../components/SelectFilter/SelectFilter';

function FilterDepl (props) {

    const deployments = useSelector(state=>state.deploymentsState.deployments);

    const handleOnChange = (v) => {
        if (props.onChange && typeof props.onChange === 'function') {
            props.onChange(v);
        }
    }

    const deploymentsList = useMemo(() => {
        if (deployments === null) {
            return [];
        }
        let index = 1;
        let items = [
            {
                value: null,
                path: 0,
                label: ''
            },
            {
                value: '',
                path: 0,
                label: 'Sem Operação'
            },
            ...(deployments.map(d => ({
            value: d.id,
            path: resolveParentPathId(d, deployments, index),
            label: resolveParentPath(d, deployments)
        })).sort((a,b) => {
            let pathA = a.path ? a.path.split('.') : [];
            let pathB = b.path ? b.path.split('.') : [];

            let largest = pathA.length > pathB.lenght ? pathA : pathB;

            for(let i in largest) {
                let idA = pathA[i] || -1;
                let idB = pathB[i] || -1;

                if (idA < idB) {
                    return -1;
                }

                if (idA > idB) {
                    return 1;
                }
            }

            return 0;
        }))];

        return items;
    }, [deployments]);
    
    return <FilterDeployment>
        <LabelDeployment>Filtrar por Operação:</LabelDeployment>
        <SelectDeployment>
            <SelectFilter value={props.value} onChange={(v)=>{handleOnChange(v)}} items={deploymentsList}/>
        </SelectDeployment>
    </FilterDeployment>
}

export default memo(FilterDepl);