export const resolveParentPath = (depl, deplList) => {
    if (depl === null) {
        return '';
    }
    if (depl.parent_id === null) {
        return (depl.nichname || depl.alias || '');
    } else {
        let parent = deplList.filter(d=>parseInt(d.id)===parseInt(depl.parent_id))[0] || {};
        return resolveParentPath(parent, deplList) + ' / ' + (depl.nichname || depl.alias || '');
    }
}

export const resolveParentPathId = (depl, deplList) => {
    if (depl === null) {
        return '';
    }

    if (depl.parent_id === null) {
        return String(depl.id);
    } else {
        let parent = deplList.filter(d=>parseInt(d.id)===parseInt(depl.parent_id))[0] || {};
        return resolveParentPathId(parent, deplList) + '.' + (depl.id || '');
    }
}
