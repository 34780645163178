import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getAssets = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/assets', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getAssetHistory = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/assets/'+id+'/history', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getAssetData = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/assets/'+id, {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postAssetData = async (data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/milliclick/new/assets', {
            payload: {
                objects: [
                    {...data}
                ]
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const updateAssetData = async (id, data) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.put(REACT_APP_HOST + '/milliclick/edit/assets/'+id, {
            payload: {
                objects: [
                    {...data}
                ]
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const deleteAsset = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/milliclick/delete/assets/', {
            data: {
                payload: {
                    objects: {
                        id
                    }
                }
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getTasksFromAsset = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/assets/'+id+'/task', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const getAssetQRCode = async (id, queryString = null) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/milliclick/assets/'+id+'/qrcode', {
            responseType: 'arraybuffer',
            params: {
                source: 'app',
                source_id: '7',
                ...queryString
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
