import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { Container, Row, Acoes, BtnAcao } from './home.styles';
import { useSelector, useDispatch } from 'react-redux';

import Title from '../../../../components/Title/Title';
import Widget from '../../../../components/Widget/Widget';
import Table from '../../../../components/Table/Table';
import useIsMounted from '../../../../hooks/isMounted';

import { getWorkorderCount, getAssetsCount } from '../../services/home';
import { getWorkOrders, getStatusAvailable } from '../../services/workorder';
import { getDeployments } from '../../services/deployments';
import FilterDeployment from '../../../../components/FilterDeployment/FilterDeployment';
import { deploymentsActions } from '../../../../redux/actions';

function Home () {

    const [data, setData] = useState({});
    const permissionsState = useSelector(state=> state.permissionsState.permissions);
    const me = useSelector(state=> state.permissionsState.me);
    const clientId = useSelector(state=> state.permissionsState.clientId);
    const [filterDeployment, setFilterDeployment] = useState(null);
    const [statusAvailable, setStatusAvailable] = useState([]);
    const [workOrderList, setWorkOrderList] = useState([]);
    const [orderBy, setOrderBy] = useState({orderBy:null, orderDir:null});

    const statusRealizadoId = useRef(null);

    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const handleAbrir = useCallback((id) => {
        //document.location = document.location.protocol + '//' + document.location.host + '/milliclick/woedit/' + (clientId || '0') + '/milliclick_485723149678/appointment?id=' + (id || '0');
        // document.location = document.location.protocol + '//' + document.location.host + '/resource/workorder/public/' + (clientId || '0') + '?pipeline=milliclick_485723149678&status=appointment&deployment=1&id=' + (id || '0');
        document.location = document.location.protocol + '/milliclick/workorder/edit/'+(clientId || '0')+'/service/'+id;
    }, [clientId]);

    const handleOrderBy = (orderBy, orderDir) => {
        setOrderBy({orderBy,orderDir});
    };

    const getListing = useCallback(async () => {
        try {
            let wo = await getWorkOrders({
                status: me.client_id === 63 ? 'Escalado' : 'Apontamento',
                type:'workorder',
                order_field:orderBy.orderBy,
                order:orderBy.orderDir,
                deployment_id: filterDeployment
            });

            if (wo && wo.payload && wo.payload.objects){
                let woList = wo.payload.objects.map(i=>i.workorder);
                setWorkOrderList(woList);
            }
        } catch (e) {
            console.error(e);
        }
    }, [filterDeployment, orderBy, me]);

    const getData = useCallback(async () => {
        try {
            let ativosCadastrados = await getAssetsCount({});

            if (permissionsState.manager) {

                let servicos = await getWorkorderCount({status:'Escalado'});//serviços em andamento
                let servicosRealizado = await getWorkorderCount({status:'Realizado Millicare'});//serviços pendentes de avaliação
                let servicosFinalizado = await getWorkorderCount({status:'Aprovado Millicare'});//serviços finalizados

                if (isMounted) {
                    setData({
                        servicos,
                        servicosRealizado,
                        servicosFinalizado,
                        ativosCadastrados
                    });
                }
            }

            if (permissionsState.facility) {
                let apontamentos = await getWorkorderCount({status:'Apontamento'});
                let apontamentosRealizados = await getWorkorderCount({status:'Realizado'});
                let apontamentosMillicare = await getWorkorderCount({status:'Escalado'});

                if (isMounted) {
                    setData({
                        apontamentos,
                        apontamentosRealizados,
                        apontamentosMillicare,
                        ativosCadastrados
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [isMounted, permissionsState]);

    const renderOptionsForStatus = useCallback((val) => {
        if (!Boolean(statusAvailable && statusAvailable[val])) {
            return null;
        }

        return statusAvailable[val].name || '';
    }, [statusAvailable]);

    useEffect(() => {
        if (clientId && permissionsState.cleaner) {
            getListing();
        }
    }, [getListing, clientId, permissionsState]);

    useEffect(() => {
        (async () => {
            let status = await getStatusAvailable();

            let statusById = {};
            if (status && status.length) {
                for (let s of status) {
                    statusById[s.id] = s;
                    if (String(s.name).toLowerCase() === 'realizado') {
                        statusRealizadoId.current = parseInt(s.id);
                    }
                }
            }

            setStatusAvailable(statusById);
        })();
    }, []);

    useEffect(() => {
        if (clientId) {
            getData(clientId);
        }
    }, [getData, clientId]);

    useEffect(() => {
        (async () => {
            try {
                let res = await getDeployments();
                let deployments = res.payload.objects.map(i=>i.deployment)
                if (deployments && deployments.length) {
                    dispatch(deploymentsActions.setDeployments(deployments));
                }
            } catch (e) {
                console.error(e);
            }
        })();
    }, [dispatch]);

    const handleFilterDeployment = (deployment_id) => {
        setFilterDeployment(deployment_id);
    };

    const columns = useMemo(() => ([
        {key:'thread_id', label:'#', width: 60},
        {key:'status', label:'Status', adapter: (val, row) => (renderOptionsForStatus(val)), width: 200},
        {key:'deployment', label:'Operação', adapter: (val, row) => (row.path_cache?row.path_cache:'').split('|').map(path=> {
            let p = path.split(':');
            return p[1] || p[0] || ''
        }).join(' / ')},
        {key:'createdBy', label:'Criado por'},
        {key:'createdAt', label:'Horário'},
        {key:'actions', label:'Ações', width: 60, adapter: (val, row) => (<Acoes>
            <BtnAcao onClick={() => handleAbrir(row.deployment_id)} icon={"\\F0349"} title="Abrir"/>
        </Acoes>)}
    ]),[handleAbrir, renderOptionsForStatus]);

    return <Container>
        <Title>Bem vindo ao Milliclick</Title>
        {permissionsState.cleaner === true ? <>
            <FilterDeployment value={filterDeployment} onChange={(v)=>{handleFilterDeployment(v)}} />
            <Table
                onOrderBy={handleOrderBy}
                columns={columns}
                data={workOrderList}
            />
        </> : null}
        {permissionsState.facility === true ? <>
        </> : null}
        {permissionsState.manager === true ? <>
            <Row>
                <Widget label="Serviços em andamento" value={data.servicos} />
                <Widget label="Serviços pendentes de Avaliação" value={data.servicosRealizado} />
                <Widget label="Serviços finalizados" value={data.servicosFinalizado} />
                <Widget label="Ativos cadastrados" value={data.ativosCadastrados} />
            </Row>
        </> : null}
        {permissionsState.facility === true ? <>
            <Row>
                <Widget label="Apontamentos em andamento" value={data.apontamentos} />
                <Widget label="Apontamentos pendentes" value={data.apontamentosRealizados} />
                <Widget label="Apontamentos com Millicare" value={data.apontamentosMillicare} />
                <Widget label="Ativos cadastrados" value={data.ativosCadastrados} />
            </Row>
        </> : null}
    </Container>
}

export default Home;
