import axios from 'axios';
import {startLoading, finishLoading} from '../../../util/loadingManager';
const { REACT_APP_HOST } = process.env;

export const getFiles = async (queryString) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.get(REACT_APP_HOST + '/resource/files', {params: {
            source: 'app',
            source_id: '7',
            ...queryString
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const postFile = async (data) => {
    let formData = new FormData();

    formData.append("source", 'app');
    formData.append("source_id", 7);
    formData.append("name", data.name);
    formData.append("name", data.name);
    formData.append("tag", data.tag);
    formData.append("file", data.file[0]);
    formData.append("deployment_id", data.deployment_id);

    const loadingId = startLoading();
    try {
        let resp = await axios.post(REACT_APP_HOST + '/new/files', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        return false;
    }
}

export const deleteFiles = async (id) => {
    const loadingId = startLoading();
    try {
        let resp = await axios.delete(REACT_APP_HOST + '/remove/files', {data: {
            payload: {
                objects: [{
                    id,
                    source: 'app',
                    source_id: '7',
                }]
            }
        }});
        finishLoading(loadingId);
        return resp.data;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};

export const downloadFile = async (id, filename, extension) => {
    const loadingId = startLoading();
    try {
        let resp = axios.get(REACT_APP_HOST + '/resource/files/'+id+'/download', {
            responseType: 'blob'
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename+'.'+extension);
            document.body.appendChild(link);
            link.click();
            finishLoading(loadingId);
        }).catch((e) => {
            console.log(e);
            finishLoading(loadingId);
        });
        return resp;
    } catch (e) {
        finishLoading(loadingId);
        console.error(e);
        return false;
    }
};
