import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 300px;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: auto;
    background-color: #333;

    @media only screen and (max-width: 900px) {
        & {
            transition: left .3s ease 0s;
            left: -310px;
            z-index: 9999;
        }

        &.opened {
            left: 0;
        }
    }
`;

export const Link = styled.a`
    cursor: pointer;
    height: 60px;
    width: 100%;
    text-align: center;
`;

export const Logo = styled.img`
    height: 40px;
    margin: 10px auto;
    width: auto;
`;

export const Separator = styled.img`
    height: 1px;
    background-color: #555;
    margin: 10px 0;
    width: 100%;
`;

export const Opener = styled.div`
    position: absolute;
    top: 20px;
    left: 10px;
    width: 24px;
    height: 24px;
    -webkit-mask: url('/app/icons/menu.svg') no-repeat center;
    mask: url('/app/icons/menu.svg') no-repeat center;
    z-index: 9999;
    background-color: #000;

    @media only screen and (min-width: 900px) {
        & {
            display: none;
        }
    }
`;

export const Background = styled.div`
    background-color: rgba(0,0,0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 100%;
    z-index: 9999;
    transition: opacity .3s ease 0s, right 0s linear .3s;
    opacity: 0;

    &.opened {
        transition: opacity .3s ease 0s, right 0s linear 0s;
        right: 0;
        opacity: 1;
    }

    @media only screen and (min-width: 900px) {
        & {
            display: none;
        }
    }
`;
