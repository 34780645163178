import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Container, SelectorClient, Label, Select, Amihub } from './Clientbar.styles';

import SelectFilter from '../SelectFilter/SelectFilter';

function Clientbar (props) {

    const client = useSelector(state => state.permissionsState.clientId);

    const handleUpdate = (val) => {
        if (typeof props.onChange === 'function') {
            props.onChange(val);
        }
    }

    const clientItems = useMemo(()=>{
        if (props.clients && props.clients.length) {
            return props.clients.map(i=>({
                value: i.clients_id,
                label: i.clients_name,
            }))
        }

        return [];
    },[props.clients]);

    return <Container>
        <SelectorClient>
            <Label>Você está em</Label>
            <Select>
                <SelectFilter value={client || ''} onChange={(v)=>{handleUpdate(v)}} items={clientItems}/>
            </Select>
        </SelectorClient>
        <Amihub href="https://ami-hub.com">
            <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 166.81 203.18"><defs><style>.cls-1{'{fill:#ffb607;}'}.cls-2{'{fill:#ffcd69;}'}.cls-3{'{fill:#c35f37;}'}.cls-4{'{fill:#ffaf28;}'}</style></defs><path className="cls-1" d="M202.92,199.25" transform="translate(-39.39 -39.39)"/><polygon className="cls-2" points="112.87 31.01 128.61 68.34 128.61 135.35 39.08 135.39 66.76 73.67 112.87 31.01"/><polygon className="cls-3" points="112.87 31.01 128.61 68.34 128.61 16.45 112.87 31.01"/><polygon className="cls-4" points="99.8 0 112.87 31.01 66.76 73.67 99.8 0"/><polygon className="cls-3" points="66.71 73.67 39.03 135.39 0 135.39 66.71 73.67"/><polygon className="cls-4" points="128.61 68.34 166.81 158.94 128.61 135.35 128.61 68.34"/><polygon className="cls-4" points="39.08 135.39 166.81 158.94 8.68 203.18 39.08 135.39"/><polygon className="cls-4" points="128.61 135.35 39.08 135.39 166.81 158.94 128.61 135.35"/></svg>
        </Amihub>
    </Container>
}

export default Clientbar;