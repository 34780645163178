import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
`;

export const Fieldset = styled.fieldset`
    border: 0px;
`;

export const Form = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;

    & #fb-editor {
        width: 100%;
    }
`;