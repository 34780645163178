import styled from 'styled-components';

export const Container = styled.div`
    width: 200px;
    height: 50px;
    background-color: #000;
    border: 1px solid #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    position: fixed;
    top: -70px;
    left: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transform: translate3d(-50%,0,0);
    z-index: 9999;
    transition: all .2s ease 0s;

    &.active {
        top: 10px;
    }
`;